const ERROR_CODES = {
  analytics: {
    END_DATE_BEFORE_START_DATE: "END_DATE_BEFORE_START_DATE"
  },

  apiKey: {
    API_KEY_NOT_EXISTS: "API_KEY_NOT_EXISTS"
  },

  asset: {
    IMAGE_DIMENSIONS_TOO_SMALL: "IMAGE_DIMENSIONS_TOO_SMALL"
  },

  authentication: {
    LOGIN_CREDENTIALS_INVALID: "LOGIN_CREDENTIALS_INVALID",
    TOKEN_INVALID: "TOKEN_INVALID"
  },

  category: {
    CATEGORY_NOT_EXISTS: "CATEGORY_NOT_EXISTS"
  },

  model: {
    PRODUCT_IDENTIFIER_EXISTS: "PRODUCT_IDENTIFIER_EXISTS",
    MODEL_NOT_EXISTS: "MODEL_NOT_EXISTS",
    UPLOAD_ERROR: "UPLOAD_ERROR"
  },

  user: {
    USER_NOT_EXISTS: "USER_NOT_EXISTS",
    EMAIL_EXISTS: "EMAIL_EXISTS",
    EMAIL_ALREADY_VERIFIED: "EMAIL_ALREADY_VERIFIED",
    EMAIL_NOT_VERIFIED: "EMAIL_NOT_VERIFIED",
    USER_NOT_ACTIVE: "USER_NOT_ACTIVE",
    USER_NOT_AUTHORIZED: "USER_NOT_AUTHORIZED",
    OLD_PASSWORD_INCORRECT: "OLD_PASSWORD_INCORRECT",
    PASSWORD_REQUIREMENTS_NOT_MET: "PASSWORD_REQUIREMENTS_NOT_MET"
  },

  team: {
    TEAM_NOT_EXISTS: "TEAM_NOT_EXISTS",
    TEAM_MEMBERSHIP_NOT_EXISTS: "TEAM_MEMBERSHIP_NOT_EXISTS",
    MEMBERSHIP_EXISTS: "MEMBERSHIP_EXISTS",
    TEAM_REQUIRES_ADMIN: "TEAM_REQUIRES_ADMIN",
    TEAM_MEMBERSHIP_NOT_ACTIVE: "TEAM_MEMBERSHIP_NOT_ACTIVE",
    TEAM_NOT_ACTIVE: "TEAM_NOT_ACTIVE",
    TEAM_MEMBERSHIP_NOT_PENDING: "TEAM_MEMBERSHIP_NOT_PENDING",
    VATID_INVALID: "VATID_INVALID",
    SUBSCRIPTION_NOT_EXISTS: "SUBSCRIPTION_NOT_EXISTS",
    PLAN_NOT_EXISTS: "PLAN_NOT_EXISTS"
  },

  role: {
    ROLE_NOT_EXISTS: "ROLE_NOT_EXISTS",
    ROLE_HAVE_MEMBERSHIPS: "ROLE_HAVE_MEMBERSHIPS",
    ROLE_EXISTS: "ROLE_EXISTS",
    ROLE_ADMIN_NOT_EDITABLE: "ROLE_ADMIN_NOT_EDITABLE"
  },

  address: {
    BILLING_ADDRESS_NOT_EXISTS: "BILLING_ADDRESS_NOT_EXISTS",
    BILLING_ADDRESS_REQUIRED: "BILLING_ADDRESS_REQUIRED"
  },

  paymentMethod: {
    PAYMENT_METHOD_NOT_EXISTS: "PAYMENT_METHOD_NOT_EXISTS"
  }
};

export default ERROR_CODES;
