import * as UserActions from "../actions/user.actions";
import { createReducer } from "./index";

export const user = createReducer(
  {
    user: undefined,
  },
  {
    [UserActions.SET_USER]: (state, action) => {
      const user = action.user;
      return user;
    },
  }
);
