import React, { Component } from "react";
import { CustomIcon } from "./CustomIcon";

export class OtherVerticalIcon extends Component {
  render() {
    return (
      <CustomIcon {...this.props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24"></rect>
          <circle
            fill={this.props.color || "currentColor"}
            cx="12"
            cy="5"
            r="2"
          ></circle>
          <circle
            fill={this.props.color || "currentColor"}
            cx="12"
            cy="12"
            r="2"
          ></circle>
          <circle
            fill={this.props.color || "currentColor"}
            cx="12"
            cy="19"
            r="2"
          ></circle>
        </g>
      </CustomIcon>
    );
  }
}

OtherVerticalIcon.propTypes = {
  ...CustomIcon.propTypes
};
