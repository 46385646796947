import React, { Component } from "react";
import * as PropTypes from "prop-types";
import ContentLoader from "react-content-loader";

export class CircleLoader extends Component {
  render() {
    return (
      <ContentLoader
        width={this.props.width || "100%"}
        height={this.props.height || "100%"}
        viewBox={`0 0 ${this.props.r * 2} ${this.props.r * 2}`}
      >
        <circle cx="50%" cy="50%" r={this.props.r} />
      </ContentLoader>
    );
  }
}

CircleLoader.propTypes = {
  r: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string
};
