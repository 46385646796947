import React, { Component } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import * as PropTypes from "prop-types";
import { userService } from "../../../services/user.service";
import ROUTES from "../../../constants/routes";
import { withRouter } from "react-router-dom";

class UserNavigation extends Component {
  render() {
    return (
      <div className={`navbar-user ${this.props.className || ""}`}>
        <UncontrolledDropdown direction={this.props.dropdownDirection} inNavbar>
          <DropdownToggle color="link" className="p-0">
            <div className="avatar avatar-sm avatar-online">
              {this.props.user?.avatarUrl ? (
                <img
                  className="avatar-img rounded-circle"
                  src={this.props.user?.avatarUrl}
                />
              ) : (
                <span className="avatar-title rounded-circle">
                  {`${this.props.user?.firstname?.charAt(0).toUpperCase() || ""}
                  ${this.props.user?.lastname?.charAt(0).toUpperCase() || ""}`}
                </span>
              )}
            </div>
          </DropdownToggle>

          <DropdownMenu>
            <DropdownItem
              onClick={() => {
                this.props.history.push(ROUTES.account.root);
              }}
            >
              {this.props.t("account")}
            </DropdownItem>

            <hr className="dropdown-divider" />

            <DropdownItem
              onClick={() => {
                this.props.history.push(ROUTES.login);
                userService.logout(true);
              }}
            >
              {this.props.t("logout")}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    location: state.router.location
  };
}

export default withRouter(
  connect(mapStateToProps)(withTranslation("nav")(UserNavigation))
);

UserNavigation.propTypes = {
  className: PropTypes.string,
  dropdownDirection: PropTypes.string
};
