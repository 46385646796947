export const SET_JUST_LOGGED_IN = "SET_JUST_LOGGED_IN";

export function setJustLoggedIn(justLoggedIn) {
  return { type: SET_JUST_LOGGED_IN, justLoggedIn };
}

export const SET_LAST_LOGIN = "SET_LAST_LOGIN";

export function setLastLogin(lastLogin) {
  return { type: SET_LAST_LOGIN, lastLogin };
}

export const RESET_STORE = "RESET_STORE";

export function resetStore() {
  return { type: RESET_STORE };
}
