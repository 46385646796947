import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import createEncryptor from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { createHashHistory } from "history";
import createRootReducer from "./reducers";

export const history = createHashHistory();

const loggerMiddleware = createLogger();

let middlewares = [];
middlewares.push(thunkMiddleware);

if (process.env.NODE_ENV === "development") {
  middlewares.push(loggerMiddleware);
}

const encryptor = createEncryptor({
  secretKey: "my-super-secret-key-hash",
  onError: function(error) {
    // Handle the error.
  }
});

const persistConfig = {
  key: "root",
  storage,
  transforms: [encryptor]
};

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

export const store = createStore(
  persistedReducer,
  compose(
    applyMiddleware(...middlewares),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f
  )
);

export const persistor = persistStore(store);
