const ROUTES = {
  login: "/auth/login",
  signUp: "/auth/sign-up",
  forgotPassword: "/auth/forgot-password",
  resetPassword: "/auth/reset-password",
  verifyEmail: "/auth/verify-email",
  home: "/app/dashboard",
  users: "/app/users",
  productList: "/app/my-3d-models",
  editor: "/app/editor",
  uploadProduct: "/app/upload-3d-model",
  account: {
    root: "/app/my-account",
    profile: "/app/my-account/profile",
    security: "/app/my-account/security",
    myTeams: "/app/my-account/my-teams"
  },
  settings: {
    root: "/app/settings",
    domains: "/app/settings/domains",
    billing: "/app/settings/billing"
  },
  manageTeam: {
    root: "/app/team/manage-team",
    general: "/app/team/manage-team/general",
    members: "/app/team/manage-team/members",
    roles: "/app/team/manage-team/roles"
  },
  productAnalytics: "/app/analytics/models",
  /** Needed to rerender react components without refreshing the whole page */
  refresh: "/refresh"
};

export default ROUTES;
