import React, { Component } from "react";
import { CustomIcon } from "./CustomIcon";

export class DoneIcon extends Component {
  render() {
    return (
      <CustomIcon {...this.props}>
        <path
          opacity="0.3"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          fill={this.props.color || "currentColor"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.769 7.81769C17.1458 7.41393 17.7786 7.39211 18.1823 7.76895C18.5861 8.14578 18.6079 8.77857 18.2311 9.18232L11.2311 16.6823C10.8655 17.074 10.2561 17.108 9.84923 16.7593L6.34923 13.7593C5.9299 13.3998 5.88134 12.7685 6.24076 12.3492C6.60018 11.9299 7.23148 11.8813 7.65081 12.2407L10.423 14.6169L16.769 7.81769Z"
          fill={this.props.color || "currentColor"}
        />
      </CustomIcon>
    );
  }
}

DoneIcon.propTypes = {
  ...CustomIcon.propTypes
};
