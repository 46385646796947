import React, { Component } from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";

class ButtonWithLoadingIndicator extends Component {
  render() {
    return (
      <Button
        color={this.props.color}
        type={this.props.type}
        className={this.props.className}
        block={this.props.block}
        onClick={this.props.onClick}
        disabled={this.props.disabled || this.props.loading}
      >
        {this.props.children}

        {this.props.loading && (
          <span className="spinner-border spinner-border-sm" />
        )}
      </Button>
    );
  }
}

ButtonWithLoadingIndicator.propTypes = {
  color: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  loading: PropTypes.bool
};

export default ButtonWithLoadingIndicator;
