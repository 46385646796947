import React, { Component } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";

export class UploadArea extends Component {
  render() {
    return (
      <Dropzone
        onDrop={files =>
          files.forEach(file => {
            // read and copy file data to avoid access issues after file was changed on disk
            const reader = new FileReader();

            reader.onload = () => {
              this.props.onFileInput(
                new File([reader.result], file.name, {
                  type: file.type
                })
              );
            };
            reader.readAsArrayBuffer(file);
          })
        }
        accept={this.props.acceptedFileTypes}
        multiple={this.props.allowMultipleFiles}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className={`${
              this.props.rounded ? "avatar-xxl" : ""
            } dropzone dropzone-single mb-3 ${
              this.props.children ? "dz-max-files-reached" : ""
            }`}
          >
            <input {...getInputProps()} />

            <span
              className={`dz-message ${
                this.props.rounded
                  ? "rounded-circle h-100 p-0 d-flex align-items-center justify-content-center font-size-sm"
                  : ""
              }`}
            >
              {this.props.message ? this.props.message : ""}
            </span>

            {this.props.children && (
              <div className="dz-preview-single">
                {React.cloneElement(this.props.children, {
                  className: `dz-preview-img ${
                    this.props.rounded ? "rounded-circle" : ""
                  } ${this.props.children?.props?.className}`
                })}
              </div>
            )}
          </div>
        )}
      </Dropzone>
    );
  }
}

UploadArea.propTypes = {
  acceptedFileTypes: PropTypes.string.isRequired,
  onFileInput: PropTypes.func.isRequired,
  message: PropTypes.string,
  allowMultipleFiles: PropTypes.bool,
  children: PropTypes.element,
  rounded: PropTypes.bool
};
