import React, { Component, Fragment } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  CustomInput,
  Row
} from "reactstrap";
import PlanCard from "./PlanCard";
import { connect } from "react-redux";
import { subscriptionService } from "../services/team/subscriptionService";
import PropTypes from "prop-types";
import { PlanInterval } from "../constants/planInterval";
import { withTranslation } from "react-i18next";

class PlanSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPlan: this.props.team.subscription.plan,
      showMonthlyPrices: false,
      yearDefaultPlans: undefined,
      monthDefaultPlans: undefined
    };
  }

  async componentDidMount() {
    await this.fetchDefaultPlans();
    this.props.onPlanSelection(this.props.team.subscription.plan);
  }

  async fetchDefaultPlans() {
    const defaultPlans = await subscriptionService.getDefaultPlans();

    const yearPlans = defaultPlans.filter(
      defaultPlan => defaultPlan.interval === PlanInterval.YEAR
    );

    const monthPlans = defaultPlans.filter(
      defaultPlan => defaultPlan.interval === PlanInterval.MONTH
    );

    this.setState({
      yearDefaultPlans: yearPlans,
      monthDefaultPlans: monthPlans
    });
  }

  selectedPlan = async plan => {
    await this.setState({
      currentPlan: plan
    });

    this.props.onPlanSelection(plan);
  };

  customInputSwitched = e => {
    this.setState({
      showMonthlyPrices: e.target.checked
    });
  };

  render() {
    return (
      <Fragment>
        <div className="pt-7 pb-8 bg-light bg-ellipses">
          <Container fluid>
            <Row className="justify-content-center">
              <Col className="col-md-10 col-lg-8 col-xl-6">
                <h1 className="display-3 text-center">
                  {this.props.t("planSelectionHeader")}
                </h1>

                <p className="lead text-center">
                  {this.props.t("planSelectionText")}
                </p>
              </Col>
            </Row>
          </Container>

          <Row className={"justify-content-center"}>
            <div>{this.props.t("toggleTextAnnually")}</div>
            <CustomInput
              className={"ml-2"}
              type="switch"
              id="annualSwitch"
              label={this.props.t("toggleTextMonthly")}
              onClick={e => this.customInputSwitched(e)}
            />
          </Row>
        </div>

        <Container fluid>
          <Row className={"mt-n7"}>
            {!this.state.showMonthlyPrices
              ? this.state.yearDefaultPlans?.map((plan, index) => (
                  <Col className={"col-12 col-lg-4"} key={index}>
                    <PlanCard
                      planName={plan.type.toUpperCase()}
                      price={plan.price}
                      maximumViews={plan.maximumViews}
                      maximumProducts={plan.maximumProducts}
                      augmentedReality={plan.augmentedReality}
                      whiteLabel={plan.whiteLabel}
                      viewerApi={plan.viewerApi}
                      analytics={plan.analytics}
                      onClick={async () => {
                        await this.selectedPlan(plan);
                      }}
                      isActive={this.state.currentPlan?.id === plan.id}
                      interval={PlanInterval.YEAR}
                    />
                  </Col>
                ))
              : this.state.monthDefaultPlans?.map((plan, index) => (
                  <Col className={"col-12 col-lg-4"} key={index}>
                    <PlanCard
                      planName={plan.type.toUpperCase()}
                      price={plan.price}
                      maximumViews={plan.maximumViews}
                      maximumProducts={plan.maximumProducts}
                      augmentedReality={plan.augmentedReality}
                      whiteLabel={plan.whiteLabel}
                      viewerApi={plan.viewerApi}
                      analytics={plan.analytics}
                      onClick={async () => {
                        await this.selectedPlan(plan);
                      }}
                      isActive={this.state.currentPlan?.id === plan.id}
                      interval={PlanInterval.MONTH}
                    />
                  </Col>
                ))}
          </Row>

          <Row>
            <Col className="col-12 col-md-6">
              <Card className="card-inactive">
                <CardBody className="d-flex flex-column align-items-center text-center">
                  <h3>{this.props.t("hardDecisionHeader")}</h3>

                  <p className="text-muted" style={{ whiteSpace: "pre-line" }}>
                    {this.props.t("hardDecisionText")}
                  </p>

                  <Button
                    onClick={() => {
                      window.open(`mailto:${process.env.SUPPORT_EMAIL}`);
                    }}
                    color={"primary"}
                  >
                    {this.props.t("contactUsButton")}
                  </Button>
                </CardBody>
              </Card>
            </Col>

            <Col className="col-12 col-md-6">
              <Card className="card-inactive">
                <CardBody className="d-flex flex-column align-items-center text-center">
                  <h3>{this.props.t("customPlanHeader")}</h3>

                  <p className="text-muted" style={{ whiteSpace: "pre-line" }}>
                    {this.props.t("customPlanText")}
                  </p>

                  <Button
                    onClick={() => {
                      window.open(`mailto:${process.env.SUPPORT_EMAIL}`);
                    }}
                    color={"primary"}
                  >
                    {this.props.t("contactUsButton")}
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

PlanSelection.propTypes = {
  onPlanSelection: PropTypes.func
};

function mapStateToProps(state) {
  return {
    team: state.team
  };
}

export default connect(mapStateToProps)(
  withTranslation("teams")(PlanSelection)
);
