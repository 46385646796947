import React, { Component } from "react";
import { CustomIcon } from "./CustomIcon";

export class TerminalIcon extends Component {
  render() {
    return (
      <CustomIcon {...this.props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <polygon points="0 0 24 0 24 24 0 24"></polygon>
          <path
            d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
            fill={this.props.color || "currentColor"}
            transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
          ></path>
          <rect
            fill={this.props.color || "currentColor"}
            opacity="0.3"
            x="12"
            y="17"
            width="10"
            height="2"
            rx="1"
          ></rect>
        </g>
      </CustomIcon>
    );
  }
}

TerminalIcon.propTypes = {
  ...CustomIcon.propTypes
};
