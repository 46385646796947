import React, { Component } from "react";
import * as PropTypes from "prop-types";
import ContentLoader from "react-content-loader";

export class RectangleLoader extends Component {
  render() {
    return (
      <ContentLoader
        width="100%"
        height="100%"
        viewBox={`0 0 ${this.props.width || 100} ${this.props.height || 100}`}
      >
        <rect
          x={this.props.x || "0"}
          y={this.props.y || "0"}
          rx={this.props.rx || "4"}
          ry={this.props.ry || "4"}
          width="100%"
          height="100%"
        />
      </ContentLoader>
    );
  }
}

RectangleLoader.propTypes = {
  x: PropTypes.string,
  y: PropTypes.string,
  rx: PropTypes.string,
  ry: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
};
