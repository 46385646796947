const PERMISSIONS = {
  teams: {
    TEAM_READ: "team-read",
    TEAM_DELETE: "team-delete",
    TEAM_UPDATE_GENERAL: "team-update-general",
    TEAM_UPDATE_DOMAINS: "team-update-domains",
    TEAM_UPDATE_VIEWER_DEFAULT_PARAMETERS:
      "team-update-viewer_default_parameters",
    TEAM_UPDATE_CUSTOM_VIEWER_ASSETS: "team-update-custom_viewer_assets",
    TEAM_MEMBERSHIPS_CREATE: "team-memberships-create",
    TEAM_MEMBERSHIPS_DELETE: "team-memberships-delete",
    TEAM_MEMBERSHIPS_UPDATE: "team-memberships-update",
    TEAM_MEMBERSHIPS_READ: "team-memberships-read"
  },

  apiKeys: {
    API_KEYS_READ: "api-keys-read",
    API_KEYS_CREATE: "api-keys-create",
    API_KEYS_UPDATE: "api-keys-update",
    API_KEYS_DELETE: "api-keys-delete"
  },
  analytics: {
    ANALYTICS_READ: "analytics-read"
  },

  products: {
    PRODUCTS_CREATE: "products-create",
    PRODUCTS_READ: "products-read",
    PRODUCTS_DELETE: "products-delete",
    PRODUCTS_UPDATE_GENERAL: "products-update-general",
    PRODUCTS_UPDATE_LIGHTING: "products-update-lighting",
    PRODUCTS_UPDATE_MATERIALS: "products-update-materials",
    PRODUCTS_UPDATE_ANNOTATIONS: "products-update-annotations",
    PRODUCTS_UPDATE_ANIMATIONS: "products-update-animations",
    PRODUCTS_UPDATE_AUGMENTED_REALITY: "products-update-augmented-reality"
  },

  subscriptions: {
    SUBSCRIPTIONS_READ: "subscriptions-read",
    SUBSCRIPTIONS_UPDATE: "subscriptions-update"
  },

  roles: {
    ROLES_READ: "roles-read",
    ROLES_CREATE: "roles-create",
    ROLES_UPDATE: "roles-update",
    ROLES_DELETE: "roles-delete"
  }
};

export default PERMISSIONS;
