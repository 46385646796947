import { combineReducers } from "redux";
import { user } from "./user.reducer";
import { upload } from "./upload.reducer";
import { misc } from "./misc.reducer";
import { team } from "./team.reducer";
import { teamMembership } from "./teamMembership.reducer";
import { connectRouter } from "connected-react-router";
import * as MiscActions from "../actions/misc.actions";

const createRootReducer = history => {
  const reducers = combineReducers({
    router: connectRouter(history),
    upload,
    user,
    misc,
    team,
    teamMembership
  });

  return function rootReducer(state, action) {
    if (action.type === MiscActions.RESET_STORE) {
      state = undefined;
    }
    return reducers(state, action);
  };
};

export function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
}

export default createRootReducer;
