import React, { Component } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  ListGroup,
  ListGroupItem,
  Row
} from "reactstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { PlanInterval } from "../constants/planInterval";

class PlanCard extends Component {
  render() {
    return (
      <Card>
        <CardBody>
          <h3 className="text-uppercase text-center ">
            <Badge color="primary" pill>
              {this.props.planName}
            </Badge>
          </h3>

          <Row className="d-flex g-0 align-items-center justify-content-center">
            <Col className={"col-auto"}>
              <h2 className="mb-0 mr-n3">€</h2>
            </Col>
            <Col className={"col-auto"}>
              <div className="display-2 count4 mb-0">{this.props.price}</div>
            </Col>
          </Row>

          <h6 className="text-uppercase text-center text-muted mb-5">
            {this.props.interval === PlanInterval.YEAR
              ? this.props.t("teams:planCardPerYear")
              : this.props.t("teams:planCardPerMonth")}
          </h6>

          <div className="mb-3">
            <ListGroup flush>
              <ListGroupItem
                className={
                  "d-flex align-items-center justify-content-between px-0"
                }
              >
                <small>
                  {this.props.t("teams:planCardViews", {
                    views: this.props.maximumViews
                  })}
                </small>
                <i className="fe fe-check-circle text-success" />
              </ListGroupItem>

              <ListGroupItem
                className={
                  "d-flex align-items-center justify-content-between px-0"
                }
              >
                <small>
                  {this.props.t("teams:planCardProducts", {
                    products: this.props.maximumProducts
                  })}
                </small>
                <i className="fe fe-check-circle text-success" />
              </ListGroupItem>

              <ListGroupItem
                className={
                  "d-flex align-items-center justify-content-between px-0"
                }
              >
                <small>{this.props.t("teams:planCardVisualEditor")}</small>
                <i className="fe fe-check-circle text-success" />
              </ListGroupItem>

              <ListGroupItem
                className={
                  "d-flex align-items-center justify-content-between px-0"
                }
              >
                <small>
                  {this.props.t("teams:planCardDomainAuthentication")}
                </small>
                <i className="fe fe-check-circle text-success" />
              </ListGroupItem>

              {this.props.analytics && (
                <ListGroupItem
                  className={
                    "d-flex align-items-center justify-content-between px-0"
                  }
                >
                  <small>{this.props.t("teams:planCardTrafficAnalysis")}</small>
                  <i className="fe fe-check-circle text-success" />
                </ListGroupItem>
              )}

              {this.props.augmentedReality && (
                <ListGroupItem
                  className={
                    "d-flex align-items-center justify-content-between px-0"
                  }
                >
                  <small>
                    {this.props.t("teams:planCardAugmentedReality")}
                  </small>
                  <i className="fe fe-check-circle text-success" />
                </ListGroupItem>
              )}

              {this.props.whiteLabel && (
                <ListGroupItem
                  className={
                    "d-flex align-items-center justify-content-between px-0"
                  }
                >
                  <small>{this.props.t("teams:planCardWhitelabel")}</small>
                  <i className="fe fe-check-circle text-success" />
                </ListGroupItem>
              )}

              {this.props.viewerApi && (
                <ListGroupItem
                  className={
                    "d-flex align-items-center justify-content-between px-0"
                  }
                >
                  <small>{this.props.t("teams:planCardApi")}</small>
                  <i className="fe fe-check-circle text-success" />
                </ListGroupItem>
              )}
            </ListGroup>
          </div>

          <Button
            onClick={this.props.onClick}
            className={"w-100"}
            color={"primary"}
            disabled={this.props.isActive}
          >
            {this.props.t("teams:planCardButtonText")}
          </Button>
        </CardBody>
      </Card>
    );
  }
}

PlanCard.propTypes = {
  planName: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  maximumViews: PropTypes.number,
  maximumProducts: PropTypes.number,
  augmentedReality: PropTypes.bool,
  whiteLabel: PropTypes.bool,
  viewerApi: PropTypes.bool,
  analytics: PropTypes.bool,
  interval: PropTypes.oneOf([PlanInterval.MONTH, PlanInterval.YEAR])
};

export default withTranslation(["teams"])(PlanCard);
