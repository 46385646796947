export function authHeader() {
  // return authorization header with jwt token
  let userToken = atob(localStorage.getItem("Vrid"));

  if (userToken) {
    return {
      Authorization: "Bearer " + userToken,
      "Accept-Language": "application/json",
      "Content-Type": "application/json"
    };
  } else {
    return {};
  }
}
