export const uploadConstants = {
  RESET: "RESET",
  General: {
    SET_ACTIVE_STEP: "SET_ACTIVE_STEP"
  },
  Models: {
    ADD_MODEL: "ADD_MODEL",
    REMOVE_MODEL: "REMOVE_MODEL",
    SET_MODEL_NAME: "SET_MODEL_NAME",
    SET_MODEL_CATEGORY: "SET_MODEL_CATEGORY",
    SET_ACTIVE_MODEL_ID: "SET_ACTIVE_MODEL_ID"
  },
  Textures: {
    ADD_TEXTURE_TO_MODEL: "ADD_TEXTURE_TO_MODEL",
    REMOVE_TEXTURE_FROM_MODEL: "REMOVE_TEXTURE_FROM_MODEL",
    SET_TEXTURE_TYPE: "SET_TEXTURE_TYPE"
  },
  TrademarkRights: {
    TOGGLE_TRADEMARK_RIGHTS_ACCEPT: "TOGGLE_TRADEMARK_RIGHTS_ACCEPT"
  },
  Upload: {
    ADD_MODEL_TO_UPLOADING_STATE: "ADD_MODEL_TO_UPLOADING_STATE",
    REMOVE_MODEL_FROM_UPLOADING_STATE: "REMOVE_MODEL_FROM_UPLOADING_STATE",
    SET_MODEL_UPLOAD_PROGRESS: "SET_MODEL_UPLOAD_PROGRESS",
    SET_MODEL_UPLOAD_CANCEL_FUNCTION: "SET_MODEL_UPLOAD_CANCEL_FUNCTION",
    SET_MODEL_UPLOAD_ERROR: "SET_MODEL_UPLOAD_ERROR"
  },
 
};
