import { userService } from "../services/user.service";

export function handleError(error) {
  if (error.response?.status === 401) {
    // auto logout if 401 response returned from api
    userService.logout();
  }

  return Promise.reject(error.response.data);
}
