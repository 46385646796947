import React, { Component } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import * as PropType from "prop-types";
import { connect } from "react-redux";
import { includesPermissions } from "../../utils/includesPermissions";
import PERMISSIONS from "../../constants/permissions";
import { COUNTRY_SELECTOR } from "../../constants/countrySelector";

class BillingAddress extends Component {
  handleChange = event => {
    const { name, value } = event.target;
    this.props.onChange(name, value);
  };

  render() {
    return (
      <Form>
        <Row>
          <Col className="col-12 col-md-12">
            <FormGroup>
              <Label>{this.props.t("settings:name")}*</Label>
              <Input
                name="name"
                value={this.props.name || ""}
                type="text"
                onChange={event => {
                  this.handleChange(event);
                }}
                disabled={
                  !includesPermissions(
                    [PERMISSIONS.subscriptions.SUBSCRIPTIONS_UPDATE],
                    this.props.teamMembership.permissions
                  )
                }
              />
            </FormGroup>
          </Col>

          <Col className="col-12 col-md-6">
            <FormGroup>
              <Label>{this.props.t("settings:streetAddress")}*</Label>

              <Input
                name="street"
                value={this.props.street || ""}
                type="text"
                onChange={event => {
                  this.handleChange(event);
                }}
                disabled={
                  !includesPermissions(
                    [PERMISSIONS.subscriptions.SUBSCRIPTIONS_UPDATE],
                    this.props.teamMembership.permissions
                  )
                }
              />
            </FormGroup>
          </Col>
          <Col className="col-12 col-md-6">
            <FormGroup>
              <Label>{this.props.t("settings:postcode")}*</Label>

              <Input
                name="postCode"
                value={this.props.postCode || ""}
                type="text"
                onChange={event => {
                  this.handleChange(event);
                }}
                disabled={
                  !includesPermissions(
                    [PERMISSIONS.subscriptions.SUBSCRIPTIONS_UPDATE],
                    this.props.teamMembership.permissions
                  )
                }
              />
            </FormGroup>
          </Col>
          <Col className="col-12 col-md-6">
            <FormGroup>
              <Label>{this.props.t("settings:city")}*</Label>

              <Input
                name="city"
                value={this.props.city || ""}
                type="text"
                onChange={event => {
                  this.handleChange(event);
                }}
                disabled={
                  !includesPermissions(
                    [PERMISSIONS.subscriptions.SUBSCRIPTIONS_UPDATE],
                    this.props.teamMembership.permissions
                  )
                }
              />
            </FormGroup>
          </Col>
          <Col className="col-12 col-md-6">
            <FormGroup>
              <Label>{this.props.t("settings:state")}</Label>

              <Input
                name="state"
                value={this.props.state || ""}
                type="text"
                onChange={event => {
                  this.handleChange(event);
                }}
                disabled={
                  !includesPermissions(
                    [PERMISSIONS.subscriptions.SUBSCRIPTIONS_UPDATE],
                    this.props.teamMembership.permissions
                  )
                }
              />
            </FormGroup>
          </Col>
          <Col className="col-12 col-md-6">
            <FormGroup>
              <Label>{this.props.t("settings:country")}*</Label>

              <Input
                type="select"
                name="country"
                value={this.props.country || ""}
                id={"countrySelector"}
                onChange={event => {
                  this.handleChange(event);
                }}
                disabled={
                  !includesPermissions(
                    [PERMISSIONS.subscriptions.SUBSCRIPTIONS_UPDATE],
                    this.props.teamMembership.permissions
                  )
                }
              >
                <option value={""} key={""}>
                  {this.props.t("settings:selectCountry")}
                </option>
                {Object.keys(COUNTRY_SELECTOR).map(key => (
                  <option
                    value={COUNTRY_SELECTOR[key].code}
                    key={COUNTRY_SELECTOR[key].code}
                  >
                    {this.props.t(`countries:${COUNTRY_SELECTOR[key].name}`)}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col className="col-12 col-md-6">
            <FormGroup>
              <Label>{this.props.t("settings:phone")}</Label>

              <Input
                name="phone"
                value={this.props.phone || ""}
                type="text"
                onChange={event => {
                  this.handleChange(event);
                }}
                disabled={
                  !includesPermissions(
                    [PERMISSIONS.subscriptions.SUBSCRIPTIONS_UPDATE],
                    this.props.teamMembership.permissions
                  )
                }
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}

BillingAddress.propTypes = {
  onChange: PropType.func,
  name: PropType.string,
  postCode: PropType.string,
  street: PropType.string,
  city: PropType.string,
  state: PropType.string,
  country: PropType.string,
  phone: PropType.string
};

function mapStateToProps(state) {
  return {
    teamMembership: state.teamMembership
  };
}

export default connect(mapStateToProps)(
  withTranslation(["settings", "countries", "notifications"])(BillingAddress)
);
