import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Col, Row } from "reactstrap";
import { UploadArea } from "./UploadArea";
import { withTranslation } from "react-i18next";

class AvatarSection extends Component {
  render() {
    return (
      <Row className="align-items-center">
        <Col className="col-auto">
          <UploadArea
            acceptedFileTypes={".png, .jpg"}
            onFileInput={file => this.props.onFileInput(file)}
            rounded
            message={this.props.t("account:upload")}
          >
            {this.props.url && <img src={this.props.url} />}
          </UploadArea>
        </Col>
        <Col className="ml-n2">
          <h4>{this.props.t("account:avatar")}</h4>
          <small className="text-muted">
            {this.props.t("account:avatarUploadRequirement")}
          </small>
        </Col>
        <Col className="col-auto">
          <Button
            color="danger"
            onClick={this.props.onDelete}
            disabled={!this.props.url}
          >
            {this.props.t("settings:delete")}
          </Button>
        </Col>
      </Row>
    );
  }
}

AvatarSection.protoType = {
  onFileInput: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  url: PropTypes.string
};

export default withTranslation("settings")(AvatarSection);
