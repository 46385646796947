import { authHeader } from "../../utils/auth-header";
import axios from "axios";
import { handleError } from "../../utils/errorHandler";

const BACKEND_URL = process.env.BACKEND_URL;

export const teamsService = {
  updateTeamDomains,
  updateTeamGeneral,
  updateTeamCustomViewerAssets,
  updateTeamViewerDefaultParams,
  switchTeam,
  getCurrentTeam,
  acceptTeamInvitation,
  leaveTeam,
  getCurrentUserMembership,
  updateTeamAvatar,
  deleteTeamAvatar
};

export function getCurrentUserMembership() {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .get(
      `${BACKEND_URL}/api/team-memberships/current-user-membership`,
      requestConfig
    )
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}

export function leaveTeam(id) {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .delete(`${BACKEND_URL}/api/team-memberships/leave/${id}`, requestConfig)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}

export function acceptTeamInvitation(id) {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .put(
      `${BACKEND_URL}/api/team-memberships/accept-invitation/${id}`,
      undefined,
      requestConfig
    )
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}

export function getCurrentTeam() {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .get(`${BACKEND_URL}/api/current-team`, requestConfig)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}

export function switchTeam(id) {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .get(`${BACKEND_URL}/api/switch-team/${id}`, requestConfig)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}

export function updateTeamCustomViewerAssets(id, data) {
  const requestConfig = {
    headers: authHeader()
  };

  const requestBody = data;

  return axios
    .put(
      `${BACKEND_URL}/api/teams/${id}/custom-viewer-assets`,
      requestBody,
      requestConfig
    )
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}

export function updateTeamViewerDefaultParams(id, data) {
  const requestConfig = {
    headers: authHeader()
  };

  const requestBody = data;

  return axios
    .put(
      `${BACKEND_URL}/api/teams/${id}/viewer-default-parameters`,
      requestBody,
      requestConfig
    )
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}

export function updateTeamGeneral(id, data) {
  const requestConfig = {
    headers: authHeader()
  };

  const requestBody = data;

  return axios
    .put(`${BACKEND_URL}/api/teams/${id}`, requestBody, requestConfig)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}

export function updateTeamDomains(id, data) {
  const requestConfig = {
    headers: authHeader()
  };

  const requestBody = data;

  return axios
    .put(`${BACKEND_URL}/api/teams/${id}/domains`, requestBody, requestConfig)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}

async function updateTeamAvatar(file, id) {
  const requestConfig = {
    headers: authHeader()
  };

  const formData = new FormData();
  formData.append("file", file);

  return axios
    .put(`${BACKEND_URL}/api/teams/${id}/avatar`, formData, requestConfig)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}

async function deleteTeamAvatar(id) {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .delete(`${BACKEND_URL}/api/teams/${id}/avatar`, requestConfig)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}
