import React, { Component } from "react";
import {
  Button,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  UncontrolledDropdown
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import ROUTES from "../../../constants/routes";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { SettingsIcon } from "../../customIcons/SettingsIcon";
import { ProductIcon } from "../../customIcons/ProductIcon";
import { HomeIcon } from "../../customIcons/HomeIcon";
import { UsersIcon } from "../../customIcons/UsersIcon";
import { BrushIcon } from "../../customIcons/BrushIcon";
import { TerminalIcon } from "../../customIcons/TerminalIcon";
import { BinocularIcon } from "../../customIcons/BinocularIcon";
import { BurgerMenuIcon } from "../../customIcons/BurgerMenuIcon";
import UserNavigation from "./UserNavigation";
import { teamsService } from "../../../services/team/teamsService";
import { membersService } from "../../../services/team/members.service";
import ERROR_CODES from "../../../constants/errorCodes";
import { openNotification } from "../../../utils/notification";
import NOTIFICATION_TYPES from "../../../constants/notificationTypes";
import { USER_TYPE } from "../../../constants/userType";
import MEMBERS_STATUS from "../../../constants/membersStatus";
import { setTeam } from "../../../store/actions/team.actions";
import { setPermissions } from "../../../store/actions/teamMembership.actions";
import PERMISSIONS from "../../../constants/permissions";
import { includesPermissions } from "../../../utils/includesPermissions";
import RoutedNavLink from "../../RoutedNavLink";

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
      teamsMemberships: undefined
    };
  }

  async componentDidMount() {
    await this.fetchUserTeams();
  }

  handleError(errorCode) {
    let messageTranslationProperty;
    switch (errorCode) {
      case ERROR_CODES.team.MEMBERSHIP_EXISTS:
        messageTranslationProperty = "notifications:errors.membershipExists";
        break;
      case ERROR_CODES.team.TEAM_MEMBERSHIP_NOT_ACTIVE:
        messageTranslationProperty = "notifications:errors.membershipNotActive";
        break;
      case ERROR_CODES.team.TEAM_MEMBERSHIP_NOT_PENDING:
        messageTranslationProperty =
          "notifications:errors.membershipNotPending";
        break;
      case ERROR_CODES.team.TEAM_MEMBERSHIP_NOT_EXISTS:
        messageTranslationProperty = "notifications:errors.membershipNotExists";
        break;
      case ERROR_CODES.team.TEAM_NOT_ACTIVE:
        messageTranslationProperty = "notifications:errors.teamNotActive";
        break;
      default:
        messageTranslationProperty = "notifications:errors.internalServerError";
    }

    openNotification(
      this.props.t(messageTranslationProperty),
      NOTIFICATION_TYPES.ERROR
    );
  }

  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  closeMenu = event => {
    if (event.target.id === "teamSwitch") {
      // don´t close mobile menu on team switch toggle click
      return;
    }

    this.setState({ menuOpen: false });
  };

  async fetchUserTeams() {
    const teamMemberships = await membersService.getCurrentUserTeamMemberships({
      status: MEMBERS_STATUS.ACTIVE
    });

    this.setState({
      teamsMemberships: teamMemberships
    });
  }

  switchTeam = async id => {
    try {
      const resp = await teamsService.switchTeam(id);

      localStorage.setItem("Vrid", btoa(resp.accessToken));

      const currentTeam = await teamsService.getCurrentTeam();
      this.props.setTeam(currentTeam);

      const teamMembership = await teamsService.getCurrentUserMembership();
      this.props.setPermissions(teamMembership.role.permissions);

      this.props.history.replace(ROUTES.refresh);
      this.props.history.replace(ROUTES.home);

      if (!currentTeam.dummy) {
        openNotification(
          this.props.t(`notifications:success.switchTeam`, {
            teamName: currentTeam.name
          }),
          NOTIFICATION_TYPES.SUCCESS
        );
      }
    } catch (error) {
      this.handleError(error.code);
    }
  };

  render() {
    return (
      <Navbar className="navbar-vertical fixed-left navbar-expand-lg navbar-light">
        <Container fluid>
          <NavLink className="d-lg-none" onClick={this.toggleMenu}>
            <BurgerMenuIcon />
          </NavLink>

          <Link
            to={ROUTES.home}
            className="navbar-brand"
            onClick={this.closeMenu}
          >
            <img
              src="/assets/Logo.svg"
              className="navbar-brand-img"
              style={{ minHeight: "48px" }}
              alt="Augmenteers - high converting product images"
            />
          </Link>

          <UserNavigation className="d-lg-none" dropdownDirection="left" />

          <Collapse
            className="navbar-collapse"
            isOpen={this.state.menuOpen}
            onClick={this.closeMenu}
          >
            <Nav navbar>
              <NavItem>
                <NavLink>
                  <UncontrolledDropdown inNavbar>
                    <DropdownToggle
                      className="p-0 d-flex align-items-center"
                      color={"black"}
                      caret={this.state.teamsMemberships?.length > 1}
                      id="teamSwitch"
                    >
                      <div
                        className="d-flex align-items-center"
                        // disable pointer-events to receive on click events only for dropdown toggle, to prevent closing mobile menu on team switch toggle click
                        style={{ pointerEvents: "none" }}
                      >
                        <div className="avatar avatar-xs">
                          {this.props.team.dummy ? (
                            <>
                              {this.props.user?.avatarUrl ? (
                                <img
                                  src={this.props.user?.avatarUrl}
                                  className="avatar-img rounded-circle"
                                />
                              ) : (
                                <span className="avatar-title rounded-circle">
                                  {this.props.team?.name
                                    ?.charAt(0)
                                    .toUpperCase()}
                                </span>
                              )}
                            </>
                          ) : (
                            <>
                              {this.props.team?.avatarUrl ? (
                                <img
                                  src={this.props.team?.avatarUrl}
                                  className="avatar-img rounded-circle"
                                />
                              ) : (
                                <span className="avatar-title rounded-circle">
                                  {this.props.team?.name
                                    ?.charAt(0)
                                    .toUpperCase()}
                                </span>
                              )}
                            </>
                          )}
                        </div>

                        <span className="ml-3">{this.props.team?.name}</span>
                      </div>
                    </DropdownToggle>

                    <DropdownMenu>
                      {this.state.teamsMemberships?.length > 1 &&
                        this.state.teamsMemberships.map(
                          (teamMembership, index) => (
                            <>
                              <DropdownItem
                                key={index}
                                onClick={async () => {
                                  await this.switchTeam(teamMembership.team.id);
                                }}
                                className="d-flex align-items-center"
                              >
                                <div className="avatar avatar-xs">
                                  {teamMembership.team.dummy ? (
                                    <>
                                      {this.props.user?.avatarUrl ? (
                                        <img
                                          src={this.props.user?.avatarUrl}
                                          className="avatar-img rounded-circle"
                                        />
                                      ) : (
                                        <span className="avatar-title rounded-circle">
                                          {teamMembership.team?.name
                                            ?.charAt(0)
                                            .toUpperCase()}
                                        </span>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {teamMembership.team?.avatarUrl ? (
                                        <img
                                          src={teamMembership.team?.avatarUrl}
                                          className="avatar-img rounded-circle"
                                        />
                                      ) : (
                                        <span className="avatar-title rounded-circle">
                                          {teamMembership.team?.name
                                            ?.charAt(0)
                                            .toUpperCase()}
                                        </span>
                                      )}
                                    </>
                                  )}
                                </div>

                                <span className="ml-2">
                                  {teamMembership.team.name}
                                </span>
                              </DropdownItem>

                              {this.state.teamsMemberships?.length - 1 !==
                                index && <DropdownItem divider />}
                            </>
                          )
                        )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </NavLink>
              </NavItem>

              {!this.props.team?.dummy &&
                includesPermissions(
                  [
                    PERMISSIONS.teams.TEAM_READ,
                    PERMISSIONS.teams.TEAM_UPDATE_GENERAL,
                    PERMISSIONS.roles.ROLES_READ,
                    PERMISSIONS.roles.ROLES_UPDATE,
                    PERMISSIONS.roles.ROLES_DELETE,
                    PERMISSIONS.roles.ROLES_CREATE,
                    PERMISSIONS.teams.TEAM_MEMBERSHIPS_READ,
                    PERMISSIONS.teams.TEAM_MEMBERSHIPS_CREATE,
                    PERMISSIONS.teams.TEAM_MEMBERSHIPS_DELETE,
                    PERMISSIONS.teams.TEAM_MEMBERSHIPS_UPDATE
                  ],
                  this.props.teamMembership.permissions
                ) &&
                this.props.teamMembership.permissions && (
                  <NavItem>
                    <RoutedNavLink to={ROUTES.manageTeam.root}>
                      <UsersIcon />
                      <span className="ml-3">
                        {this.props.t("nav:manageTeam")}
                      </span>
                    </RoutedNavLink>
                  </NavItem>
                )}
            </Nav>

            <hr className="navbar-divider" />
            <Nav navbar>
              <NavItem>
                <RoutedNavLink to={ROUTES.home}>
                  <HomeIcon />
                  <span className="ml-3">{this.props.t("nav:dashboard")}</span>
                </RoutedNavLink>
              </NavItem>

              {this.props.user.type === USER_TYPE.ADMIN && (
                <NavItem>
                  <RoutedNavLink to={ROUTES.users}>
                    <UsersIcon />
                    <span className="ml-3">{this.props.t("nav:users")}</span>
                  </RoutedNavLink>
                </NavItem>
              )}

              {includesPermissions(
                [
                  PERMISSIONS.products.PRODUCTS_READ,
                  PERMISSIONS.products.PRODUCTS_DELETE,
                  PERMISSIONS.products.PRODUCTS_UPDATE_GENERAL,
                  PERMISSIONS.products.PRODUCTS_UPDATE_AUGMENTED_REALITY,
                  PERMISSIONS.products.PRODUCTS_UPDATE_MATERIALS,
                  PERMISSIONS.products.PRODUCTS_UPDATE_ANNOTATIONS,
                  PERMISSIONS.products.PRODUCTS_UPDATE_ANIMATIONS,
                  PERMISSIONS.products.PRODUCTS_UPDATE_LIGHTING
                ],
                this.props.teamMembership.permissions
              ) && (
                <NavItem>
                  <RoutedNavLink to={ROUTES.productList}>
                    <ProductIcon />
                    <span className="ml-3">{this.props.t("nav:products")}</span>
                  </RoutedNavLink>
                </NavItem>
              )}
              {includesPermissions(
                [
                  PERMISSIONS.teams.TEAM_UPDATE_DOMAINS,
                  PERMISSIONS.subscriptions.SUBSCRIPTIONS_READ,
                  PERMISSIONS.subscriptions.SUBSCRIPTIONS_UPDATE
                ],
                this.props.teamMembership.permissions
              ) && (
                <NavItem>
                  <RoutedNavLink to={ROUTES.settings.root}>
                    <SettingsIcon />
                    <span className="ml-3">{this.props.t("nav:settings")}</span>
                  </RoutedNavLink>
                </NavItem>
              )}
            </Nav>

            <hr className="navbar-divider" />

            <h6 className="navbar-heading">
              {this.props.t("nav:usefulTools")}
            </h6>

            <Nav navbar>
              <NavItem>
                <NavLink
                  id="apiDocumentation"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={process.env.DOCUMENTATION_URL}
                >
                  <TerminalIcon />
                  <span className="ml-3">
                    {this.props.t("nav:apiDocumentation")}
                  </span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  id="assetPreview"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={process.env.PREVIEWER_URL}
                >
                  <BinocularIcon />
                  <span className="ml-3">
                    {this.props.t("nav:assetPreview")}
                  </span>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  id="requestContentCreation"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={process.env.CONTENT_CREATION_FORM_URL}
                >
                  <BrushIcon />
                  <span className="ml-3">
                    {this.props.t("nav:requestContentCreation")}
                  </span>
                </NavLink>
              </NavItem>
            </Nav>

            <div className="mt-auto mb-3 d-flex flex-column align-items-center ">
              {includesPermissions(
                [PERMISSIONS.products.PRODUCTS_CREATE],
                this.props.teamMembership.permissions
              ) && (
                <Link
                  className={"w-100"}
                  to={ROUTES.uploadProduct}
                  id="addNewProductButton"
                >
                  <Button className={"w-100"} color="primary">
                    {this.props.t("nav:addNewProductButton")}
                  </Button>
                </Link>
              )}
            </div>

            <UserNavigation
              className="d-none d-lg-flex"
              dropdownDirection="up"
            />
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setTeam: team => {
      dispatch(setTeam(team));
    },
    setPermissions: permissions => {
      dispatch(setPermissions(permissions));
    }
  };
};

function mapStateToProps(state) {
  return {
    user: state.user,
    team: state.team,
    teamMembership: state.teamMembership
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation(["notifications", "nav"])(Navigation))
);
