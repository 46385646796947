import React, { Component } from "react";
import { connect } from "react-redux";
import { setUser } from "../../../store/actions";
import { withTranslation } from "react-i18next";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { setTeam } from "../../../store/actions/team.actions";
import * as PropTypes from "prop-types";
import { userService } from "../../../services/user.service";
import AvatarSection from "../../../components/AvatarSection";

class Profile extends Component {
  handleChange = event => {
    const { name, value } = event.target;

    this.props.onChange(name, value);
  };

  render() {
    return (
      <FormGroup>
        <AvatarSection
          onFileInput={async file => {
            const user = await userService.updateUserAvatar(
              file,
              this.props.user.id
            );
            this.props.setUser(user);
          }}
          onDelete={async () => {
            const user = await userService.deleteUserAvatar(this.props.user.id);
            this.props.setUser(user);
          }}
          url={this.props.user.avatarUrl}
        />

        <hr className="navbar-divider" />

        <Row>
          <Col className="col-12 col-md-6">
            <FormGroup>
              <Label>{this.props.t("settings:firstName")}</Label>

              <Input
                name="firstname"
                defaultValue={this.props.user.firstname || ""}
                type="text"
                onChange={this.handleChange}
              />
            </FormGroup>
          </Col>
          <Col className="col-12 col-md-6">
            <FormGroup>
              <Label>{this.props.t("settings:lastName")}</Label>

              <Input
                name="lastname"
                defaultValue={this.props.user.lastname || ""}
                type="text"
                onChange={this.handleChange}
              />
            </FormGroup>
          </Col>
          <Col className="col-12">
            <FormGroup>
              <Label className="mb-1">{this.props.t("settings:email")}</Label>

              <Input
                name="email"
                defaultValue={this.props.user.email || ""}
                type="email"
                onChange={this.handleChange}
                required
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col className="col-12 col-md-6">
            <FormGroup>
              <Label>{this.props.t("settings:streetAddress")}</Label>

              <Input
                name="street"
                defaultValue={this.props.user.street || ""}
                type="text"
                onChange={this.handleChange}
              />
            </FormGroup>
          </Col>
          <Col className="col-12 col-md-6">
            <FormGroup>
              <Label>{this.props.t("settings:postcode")}</Label>

              <Input
                name="postCode"
                defaultValue={this.props.user.postCode || ""}
                type="text"
                onChange={this.handleChange}
              />
            </FormGroup>
          </Col>
          <Col className="col-12 col-md-6">
            <FormGroup>
              <Label>{this.props.t("settings:city")}</Label>

              <Input
                name="city"
                defaultValue={this.props.user.city || ""}
                type="text"
                onChange={this.handleChange}
              />
            </FormGroup>
          </Col>

          <Col className="col-12 col-md-6">
            <FormGroup>
              <Label>{this.props.t("settings:state")}</Label>

              <Input
                name="state"
                defaultValue={this.props.user.state || ""}
                type="text"
                onChange={this.handleChange}
              />
            </FormGroup>
          </Col>

          <Col className="col-12 col-md-6">
            <FormGroup>
              <Label>{this.props.t("settings:country")}</Label>

              <Input
                name="country"
                defaultValue={this.props.user.country || ""}
                type="text"
                onChange={this.handleChange}
              />
            </FormGroup>
          </Col>
          <Col className="col-12 col-md-6">
            <FormGroup>
              <Label>{this.props.t("settings:phone")}</Label>

              <Input
                name="phone"
                defaultValue={this.props.user.phone || ""}
                type="tel"
                onChange={this.handleChange}
              />
            </FormGroup>
          </Col>
          <Col className="col-12">
            <FormGroup>
              <Label className="mb-1">
                {this.props.t("settings:jobDescription")}
              </Label>

              <Input
                name="jobDescription"
                defaultValue={this.props.user.jobDescription || ""}
                type="jobDescription"
                onChange={this.handleChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </FormGroup>
    );
  }
}

Profile.prototypes = {
  onChange: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    setUser: user => {
      dispatch(setUser(user));
    },

    setTeam: team => {
      dispatch(setTeam(team));
    }
  };
};

function mapStateToProps(state) {
  return {
    user: state.user,
    team: state.team
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["settings", "account", "notifications"])(Profile));
