import React, { Component } from "react";
import { CustomIcon } from "./CustomIcon";

export class EyeOutlinedIcon extends Component {
  render() {
    return (
      <CustomIcon {...this.props} viewBox="0 0 24 24">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24"></rect>
          <path
            d="M3,12 C3,12 5.45454545,6 12,6 C16.9090909,6 21,12 21,12 C21,12 16.9090909,18 12,18 C5.45454545,18 3,12 3,12 Z"
            stroke={this.props.color || "currentColor"}
            strokeWidth="1px"
          ></path>
          <path
            d="M12,15 C10.3431458,15 9,13.6568542 9,12 C9,10.3431458 10.3431458,9 12,9 C13.6568542,9 15,10.3431458 15,12 C15,13.6568542 13.6568542,15 12,15 Z"
            stroke={this.props.color || "currentColor"}
            strokeWidth="1px"
          ></path>
        </g>
      </CustomIcon>
    );
  }
}

EyeOutlinedIcon.propTypes = {
  ...CustomIcon.propTypes
};
