import React, { Component } from "react";
import { Link } from "react-router-dom";
import ROUTES from "../../constants/routes";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { openNotification } from "../../utils/notification";
import { withTranslation } from "react-i18next";
import ERROR_CODES from "../../constants/errorCodes";
import NOTIFICATION_TYPES from "../../constants/notificationTypes";
import { userService } from "../../services/user.service";
import ButtonWithLoadingIndicator from "../../components/ButtonWithLoadingIndicator";

class ForgotPassword extends Component {
  constructor() {
    super();

    this.state = {
      username: "",
      isLoading: false
    };
  }

  handleError(errorCode) {
    let messageTranslationProperty;
    switch (errorCode) {
      case ERROR_CODES.user.USER_NOT_EXISTS:
        messageTranslationProperty = "notifications:errors.userNotExists";
        break;
      default:
        messageTranslationProperty = "notifications:errors.internalServerError";
    }

    openNotification(
      this.props.t(messageTranslationProperty),
      NOTIFICATION_TYPES.ERROR
    );
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ isLoading: true });

    const username = this.state.username;

    if (username) {
      try {
        await userService.forgotPassword(username);

        openNotification(
          this.props.t("notifications:success.passwordForgot"),
          NOTIFICATION_TYPES.SUCCESS
        );

        this.props.history.push(ROUTES.login);
      } catch (error) {
        this.handleError(error.code);
      }

      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <Row noGutters className="vh-100 bg-white">
        <Col
          className="col-12 col-md-8 order-md-1"
          style={{
            backgroundImage: "url('/assets/Forgot_Password.png')",
            backgroundSize: "cover",
            backgroundPosition: "50% 50%",
            width: "100%",
            minHeight: "300px"
          }}
        />

        <Col className="col-12 col-md-4 order-md-0 d-flex flex-column align-items-center justify-content-center p-5">
          <img
            src="/assets/Logo.svg"
            title="Augmenteers Logo"
            alt={this.props.t("authentication:authenticationImageAltText")}
            width="220px"
            height="46px"
            className="mb-3"
          />

          <h1 className="display-4 mb-3">
            {this.props.t("authentication:passwordResetHeading")}
          </h1>

          <p className="text-muted mb-5">
            {this.props.t("authentication:passwordResetText")}
          </p>

          <Form onSubmit={this.handleSubmit} className="w-100">
            <FormGroup>
              <Label>
                {this.props.t("authentication:emailAddressInputLabel")}
              </Label>

              <Input
                name="username"
                type="email"
                value={this.state.username}
                required
                onChange={this.handleChange}
              />
            </FormGroup>

            <FormGroup>
              <ButtonWithLoadingIndicator
                color="primary"
                type="submit"
                block
                disabled={!this.state.username}
                loading={this.state.isLoading}
              >
                {this.props.t("authentication:newPasswordButton")}
              </ButtonWithLoadingIndicator>
            </FormGroup>
          </Form>

          <small className="text-muted mb-2">
            {this.props.t("authentication:noAccountText")}{" "}
            <Link to={ROUTES.signUp}>
              {this.props.t("authentication:registrationButton")}
            </Link>
          </small>

          <small className="text-muted">
            {this.props.t("authentication:rememberPasswordText")}{" "}
            <Link to={ROUTES.login}>
              {this.props.t("authentication:loginButton")}
            </Link>
          </small>
        </Col>
      </Row>
    );
  }
}

export default withTranslation(["notifications", "authentication"])(
  ForgotPassword
);
