import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import ROUTES from "../../constants/routes";
import { setUser } from "../../store/actions";
import { withTranslation } from "react-i18next";
import { openNotification } from "../../utils/notification";
import ERROR_CODES from "../../constants/errorCodes";
import NOTIFICATION_TYPES from "../../constants/notificationTypes";
import { userService } from "../../services/user.service";
import PasswordInput from "../../components/PasswordInput";
import { teamsService } from "../../services/team/teamsService";
import {
  setJustLoggedIn,
  setLastLogin
} from "../../store/actions/misc.actions";
import { setTeam } from "../../store/actions/team.actions";
import { setPermissions } from "../../store/actions/teamMembership.actions";
import ButtonWithLoadingIndicator from "../../components/ButtonWithLoadingIndicator";

class Login extends Component {
  constructor() {
    super();

    this.state = {
      username: "",
      password: "",
      isLoading: false
    };
  }

  handleError(errorCode) {
    let messageTranslationProperty;
    switch (errorCode) {
      case ERROR_CODES.authentication.LOGIN_CREDENTIALS_INVALID:
        messageTranslationProperty =
          "notifications:errors.loginInvalidCredentials";
        break;
      case ERROR_CODES.user.EMAIL_NOT_VERIFIED:
        messageTranslationProperty = "notifications:errors.emailNotVerified";
        break;
      case ERROR_CODES.user.USER_NOT_ACTIVE:
        messageTranslationProperty = "notifications:errors.userNotActive";
        break;
      default:
        messageTranslationProperty = "notifications:errors.internalServerError";
    }

    openNotification(
      this.props.t(messageTranslationProperty),
      NOTIFICATION_TYPES.ERROR
    );
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ isLoading: true });

    const { username, password } = this.state;

    try {
      const response = await userService.login(username, password);
      localStorage.setItem("Vrid", btoa(response.accessToken));
      this.props.setLastLogin(response.lastLogin);

      const userData = await userService.getCurrentUser();
      this.props.setUser(userData);

      const currentTeam = await teamsService.getCurrentTeam();
      this.props.setTeam(currentTeam);

      const teamMembership = await teamsService.getCurrentUserMembership();

      this.props.setPermissions(teamMembership.role.permissions);

      let notification = this.props.t(`notifications:success.login`);

      if (!currentTeam.dummy) {
        notification +=
          "\n" +
          this.props.t(`notifications:success.switchTeam`, {
            teamName: currentTeam.name
          });
      }

      openNotification(notification, NOTIFICATION_TYPES.SUCCESS);

      this.props.history.push({
        pathname: ROUTES.home,
        state: this.props.location.state
      });
      this.props.setJustLoggedIn(true);
    } catch (error) {
      this.handleError(error.code);
    }

    this.setState({ isLoading: false });
  };

  render() {
    return (
      <Row noGutters className="vh-100 bg-white">
        <Col
          className="col-12 col-md-8 order-md-1"
          style={{
            backgroundImage: "url('/assets/Login_Fingerprint.png')",
            backgroundSize: "cover",
            backgroundPosition: "50% 50%",
            width: "100%",
            minHeight: "300px"
          }}
        />

        <Col className="col-12 col-md-4 order-md-0 d-flex flex-column align-items-center justify-content-center p-5">
          <img
            src="/assets/Logo.svg"
            alt={this.props.t("authentication:authenticationImageAltText")}
            width="220px"
            height="46px"
            className="mb-3"
          />

          <h1 className="display-4 mb-3">
            {this.props.t("authentication:loginPageHeading")}
          </h1>

          <p className="text-muted mb-5">
            {this.props.t("authentication:loginPageText")}
          </p>

          <Form onSubmit={this.handleSubmit} className="w-100">
            <FormGroup>
              <Label>
                {this.props.t("authentication:emailAddressInputLabel")}
              </Label>

              <Input
                name="username"
                type="email"
                value={this.state.username}
                required
                onChange={this.handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Row>
                <Col>
                  <Label>
                    {" "}
                    {this.props.t("authentication:passwordInputLabel")}
                  </Label>
                </Col>
                <Col className="col-auto">
                  <small className="text-muted">
                    <Link to={ROUTES.forgotPassword}>
                      {this.props.t("authentication:passwordForgotText")}
                    </Link>
                  </small>
                </Col>
              </Row>

              <PasswordInput
                value={this.state.password}
                name="password"
                required
                onChange={this.handleChange}
              />
            </FormGroup>

            <FormGroup>
              <ButtonWithLoadingIndicator
                color="primary"
                type="submit"
                block
                disabled={!(!!this.state.username && !!this.state.password)}
                loading={this.state.isLoading}
              >
                {this.props.t("authentication:loginButton")}
              </ButtonWithLoadingIndicator>
            </FormGroup>
          </Form>

          <small className="text-muted">
            {this.props.t("authentication:noAccountText")}{" "}
            <Link to={ROUTES.signUp}>
              {this.props.t("authentication:registrationButton")}
            </Link>
          </small>
        </Col>
      </Row>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUser: user => {
      dispatch(setUser(user));
    },

    setJustLoggedIn: justLoggedIn => {
      dispatch(setJustLoggedIn(justLoggedIn));
    },

    setLastLogin: lastLogin => {
      dispatch(setLastLogin(lastLogin));
    },

    setTeam: team => {
      dispatch(setTeam(team));
    },

    setPermissions: permissions => {
      dispatch(setPermissions(permissions));
    }
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(withTranslation(["notifications", "authentication"])(Login))
);
