import React, { Component, Fragment } from "react";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { EyeOutlinedIcon } from "./customIcons/EyeOutlinedIcon";
import * as PropTypes from "prop-types";
import PasswordStrengthBar from "react-password-strength-bar";
import { withTranslation } from "react-i18next";

class PasswordInput extends Component {
  constructor() {
    super();

    this.state = {
      showPassword: false
    };
  }

  render() {
    return (
      <Fragment>
        <InputGroup className="input-group-merge">
          <Input
            className="form-control-appended"
            name={this.props.name}
            type={this.state.showPassword ? "text" : "password"}
            value={this.props.value}
            required={this.props.required}
            onChange={this.props.onChange}
          />

          <InputGroupAddon addonType="append" className="hover-pointer">
            <InputGroupText
              className={
                this.state.showPassword ? "text-primary" : "text-muted"
              }
              onClick={() => {
                this.setState({
                  showPassword: !this.state.showPassword
                });
              }}
            >
              <EyeOutlinedIcon width="20px" height="20px" />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>

        {this.props.showPasswordStrength && (
          <PasswordStrengthBar
            className="password-strength-bar-container"
            password={this.props.value}
            scoreWords={[
              this.props.t("veryWeak"),
              this.props.t("weak"),
              this.props.t("okay"),
              this.props.t("good"),
              this.props.t("strong")
            ]}
            shortScoreWord={this.props.t("tooShortPasswordText")}
            barColors={["#ddd", "#ef4836", "#f6b44d", "#2b90ef", "#00D97E"]}
            minLength={8}
          />
        )}
      </Fragment>
    );
  }
}

PasswordInput.propTypes = {
  value: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  showPasswordStrength: PropTypes.bool
};

export default withTranslation("authentication")(PasswordInput);
