import React, { Component, Fragment } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import Domains from "../routes/settings/Domains";
import { connect } from "react-redux";
import { setLastLogin } from "../store/actions/misc.actions";
import PlanUpgrade from "./PlanUpgrade";
import { Step, Stepper } from "react-form-stepper";
import Profile from "../routes/users/account/Profile";
import { userService } from "../services/user.service";
import { setUser } from "../store/actions";
import { withTranslation } from "react-i18next";

class FirstLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.user,
      step: 0
    };
  }

  handleContinueClick = async () => {
    if (this.state.step === 1) {
      await this.handleProfileSubmit();
    }

    this.setState({
      step: this.state.step + 1
    });
  };

  handleChange = async profileInformation => {
    await this.setState({
      ...profileInformation
    });
  };

  handleProfileSubmit = async () => {
    const {
      id,
      firstname,
      lastname,
      email,
      street,
      postCode,
      city,
      country,
      state,
      phone,
      jobDescription
    } = this.state;

    await this.setState({ isSubmitting: !this.state.isSubmitting });

    const user = await userService.update(id, {
      firstname: firstname,
      lastname: lastname,
      email: email,
      street: street,
      postCode: postCode,
      city: city,
      country: country,
      state: state,
      phone: phone,
      jobDescription: jobDescription
    });

    this.props.setUser(user);

    await this.setState({ isSubmitting: !this.state.isSubmitting });
  };

  getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <PlanUpgrade
            isFirstLogin={true}
            upgradeFinished={this.handleContinueClick}
          />
        );
      case 1:
        return <Profile onChange={this.handleChange} />;
      case 2:
        return <Domains />;
      default:
        this.props.setLastLogin(new Date());
    }
  };

  getSteps = () => {
    return [
      this.props.t("teams:stepperPlan"),
      this.props.t("teams:stepperProfileInformation"),
      this.props.t("teams:stepperDomains")
    ];
  };

  render() {
    const steps = this.getSteps();
    return (
      <Fragment>
        <Row className="justify-content-center">
          <Col className="col-12 col-md-11">
            <Stepper
              activeStep={this.state.step}
              className="mb-3"
              styleConfig={{
                activeBgColor: "#2c7be5",
                completedBgColor: "#00d97e",
                inactiveBgColor: "#6e84a3"
              }}
            >
              {steps?.map(label => (
                <Step key={label} label={label} />
              ))}
            </Stepper>
            {this.getStepContent(this.state.step)}
          </Col>
        </Row>

        {this.state.step !== 0 && (
          <Container>
            <Row className="d-flex justify-content-end align-items-center mr-md-0 mr-lg-4 ">
              <Col className="col-auto">
                <a onClick={this.handleContinueClick}>
                  {this.props.t("teams:skipButton")}
                </a>
              </Col>

              <Col className="col-auto">
                <Button color={"primary"} onClick={this.handleContinueClick}>
                  {this.state.step === 2
                    ? this.props.t("teams:finishButton")
                    : this.props.t("teams:continueButton")}
                </Button>
              </Col>
            </Row>
          </Container>
        )}
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUser: user => {
      dispatch(setUser(user));
    },
    setLastLogin: lastLogin => {
      dispatch(setLastLogin(lastLogin));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["teams"])(FirstLogin));
