import React, { Component, Fragment } from "react";
import {
  Elements,
  ElementsConsumer,
  PaymentElement
} from "@stripe/react-stripe-js";
import { billingService } from "../../services/billing.service";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Form } from "reactstrap";
import { withTranslation } from "react-i18next";
import { RectangleLoader } from "../contentLoaders/RectangleLoader";
import PropTypes from "prop-types";

class AddPaymentMethodForm extends Component {
  constructor() {
    super();

    this.state = {
      stripePromise: undefined,
      options: undefined,
      stripeElementsReady: false
    };
  }

  async componentDidMount() {
    const stripePromise = loadStripe(process.env.STRIPE_KEY);
    const { stripeClientSecret } = await billingService.getStripeClientSecret();

    this.setState({
      stripePromise: stripePromise,
      options: { clientSecret: stripeClientSecret }
    });
  }

  addPaymentMethod = async (event, stripe, stripeElements) => {
    event.preventDefault();

    const response = await stripe.confirmSetup({
      elements: stripeElements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: "test"
          }
        },
        return_url: window.location.href
      },
      //only want redirect for redirect-based payments
      redirect: "if_required"
    });

    if (response.error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      this.props.onError(response.error);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      this.props.onSuccess(response.setupIntent.payment_method);
    }
  };

  render() {
    return (
      <Fragment>
        {this.state.stripePromise && this.state.options && (
          <Elements
            stripe={this.state.stripePromise}
            options={this.state.options}
          >
            <ElementsConsumer>
              {({ stripe, elements }) => (
                <Form
                  onSubmit={event =>
                    this.addPaymentMethod(event, stripe, elements)
                  }
                >
                  <PaymentElement
                    onReady={() => {
                      this.setState({ stripeElementsReady: true });
                    }}
                  />
                  {this.state.stripeElementsReady ? (
                    <Button
                      type="submit"
                      color="primary"
                      className="float-right"
                      disabled={!stripe || !elements}
                    >
                      {this.props.t("addPaymentMethodFormSubmitButton")}
                    </Button>
                  ) : (
                    <RectangleLoader rx="3" ry="3" width="284" height="120" />
                  )}
                </Form>
              )}
            </ElementsConsumer>
          </Elements>
        )}
      </Fragment>
    );
  }
}

export default withTranslation("settings")(AddPaymentMethodForm);

AddPaymentMethodForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
};
