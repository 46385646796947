import axios from "axios";
import { authHeader } from "../utils/auth-header";
import { handleError } from "../utils/errorHandler";
import { resetStore } from "../store/actions/misc.actions";
import { store } from "../store";

const BACKEND_URL = process.env.BACKEND_URL;

export const userService = {
  forgotPassword,
  resetPassword,
  login,
  logout,
  register,
  getCurrentUser,
  getAll,
  update,
  changePassword,
  verifyEmail,
  updateUserAvatar,
  deleteUserAvatar
};

async function verifyEmail(token) {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .get(`${BACKEND_URL}/api/email/verify/${token}`, requestConfig)
    .then(res => {
      return res;
    })
    .catch(error => {
      return handleError(error);
    });
}

async function forgotPassword(username) {
  const requestConfig = {
    headers: authHeader()
  };

  const requestBody = {
    email: username
  };

  return axios
    .post(`${BACKEND_URL}/api/password/forgot`, requestBody, requestConfig)
    .then(res => {
      return res;
    })
    .catch(error => {
      return handleError(error);
    });
}

async function resetPassword(username, password, token) {
  const requestConfig = {
    headers: authHeader()
  };

  const requestBody = {
    email: username,
    password: password,
    token: token
  };

  return axios
    .post(`${BACKEND_URL}/api/password/reset`, requestBody, requestConfig)
    .then(res => {
      return res;
    })
    .catch(error => {
      return handleError(error);
    });
}

async function login(username, password) {
  const requestConfig = {
    headers: authHeader()
  };

  const requestBody = {
    username: username,
    password: password,
    grant_type: "password",
    scope: null
  };

  return axios
    .post(`${BACKEND_URL}/api/login`, requestBody, requestConfig)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error, false);
    });
}

async function register(
  firstname,
  lastname,
  email,
  password,
  password_confirmation
) {
  const requestConfig = {
    headers: { "Content-Type": "application/json" }
  };

  const requestBody = {
    firstname,
    lastname,
    email,
    password,
    password_confirmation
  };

  return axios
    .post(`${BACKEND_URL}/api/register`, requestBody, requestConfig)
    .then(res => {
      return res;
    })
    .catch(error => {
      return handleError(error);
    });
}

async function update(id, data) {
  const requestConfig = {
    headers: authHeader()
  };

  const requestBody = data;

  return axios
    .put(`${BACKEND_URL}/api/users/${id}`, requestBody, requestConfig)
    .then(res => {
      return res.data.data;
    })
    .catch(error => {
      return handleError(error);
    });
}

async function changePassword(data) {
  const requestConfig = {
    headers: authHeader()
  };

  const requestBody = {
    old_password: data.oldPassword,
    new_password: data.newPassword
  };

  return axios
    .post(
      `${BACKEND_URL}/api/my-account/update-password`,
      requestBody,
      requestConfig
    )
    .then(res => {
      return res.data.data;
    })
    .catch(error => {
      return handleError(error);
    });
}

function logout(reloadPage = true) {
  // remove user from local storage to log user out
  if (localStorage) {
    localStorage.clear();
  }

  if (sessionStorage) {
    sessionStorage.clear();
  }

  store.dispatch(resetStore());

  if (reloadPage === true) {
    window.location.reload();
  }
}

async function getCurrentUser() {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .get(`${BACKEND_URL}/api/my-account`, requestConfig)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}

async function getAll() {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .get(`${BACKEND_URL}/api/users`, requestConfig)
    .then(res => {
      return res.data.data;
    })
    .catch(error => {
      return handleError(error);
    });
}

async function updateUserAvatar(file, id) {
  const requestConfig = {
    headers: authHeader()
  };

  const formData = new FormData();
  formData.append("file", file);

  return axios
    .put(`${BACKEND_URL}/api/users/${id}/avatar`, formData, requestConfig)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}

async function deleteUserAvatar(id) {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .delete(`${BACKEND_URL}/api/users/${id}/avatar`, requestConfig)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}
