const STRIPE_VAT_ID_TYPES = {
  AUSTRALIA: "au_abn",
  AUSTRIA: "eu_vat",
  BELGIUM: "eu_vat",
  BRAZIL: "br_cnpj",
  BULGARIA: "eu_vat",
  CANADA: "ca_bn",
  CHILE: "cl_tin",
  CROATIA: "eu_vat",
  CYPRUS: "eu_vat",
  CZECH_REPUBLIC: "eu_vat",
  DENMARK: "eu_vat",
  ESTONIA: "eu_vat",
  FINLAND: "eu_vat",
  FRANCE: "eu_vat",
  GEORGIA: "ge_vat",
  GERMANY: "eu_vat",
  GREECE: "eu_vat",
  HONG_KONG: "hk_br",
  HUNGARY: "eu_vat",
  ICELAND: "is_vat",
  INDIA: "in_gst",
  INDONESIA: "id_npwp",
  IRELAND: "eu_vat",
  ISRAEL: "il_vat",
  ITALY: "eu_vat",
  JAPAN: "jp_cn",
  LATVIA: "eu_vat",
  LIECHTENSTEIN: "li_uid",
  LITHUANIA: "eu_vat",
  LUXEMBOURG: "eu_vat",
  MALAYSIA: "my_itn",
  MALTA: "eu_vat",
  MEXICO: "mx_rfc",
  NETHERLANDS: "eu_vat",
  NEW_ZEALAND: "nz_gst",
  NORWAY: "no_vat",
  POLAND: "eu_vat",
  PORTUGAL: "eu_vat",
  ROMANIA: "eu_vat",
  RUSSIA: "ru_inn",
  SAUDI_ARABIA: "sa_vat",
  SINGAPORE: "sg_gst",
  SLOVAKIA: "eu_vat",
  SLOVENIA: "eu_vat",
  SOUTH_AFRICA: "za_vat",
  SOUTH_KOREA: "kr_brn",
  SPAIN: "eu_vat",
  SWEDEN: "eu_vat",
  SWITZERLAND: "ch_vat",
  TAIWAN: "tw_vat",
  THAILAND: "th_vat",
  UKRAINE: "ua_vat",
  UNITED_ARAB_EMIRATES: "ae_trn",
  UNITED_KINGDOM: "eu_vat",
  UNITED_STATES: "us_ein"
};


export const COUNTRY_SELECTOR = {
  australia: {
    name: "australia",
    code: "AU",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.AUSTRALIA
  },

  austria: {
    name: "austria",
    code: "AT",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.AUSTRIA
  },

  belgium: {
    name: "belgium",
    code: "BE",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.BELGIUM
  },

  brazil: {
    name: "brazil",
    code: "BR",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.BRAZIL
  },

  bulgaria: {
    name: "bulgaria",
    code: "BG",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.BULGARIA
  },

  canada: {
    name: "canada",
    code: "CA",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.CANADA
  },

  chile: {
    name: "chile",
    code: "CL",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.CHILE
  },

  croatia: {
    name: "croatia",
    code: "HR",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.BULGARIA
  },

  cyprus: {
    name: "cyprus",
    code: "CY",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.CYPRUS
  },

  czechRepublic: {
    name: "czechRepublic",
    code: "CZ",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.CZECH_REPUBLIC
  },

  denmark: {
    name: "denmark",
    code: "DK",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.DENMARK
  },

  estonia: {
    name: "estonia",
    code: "EE",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.ESTONIA
  },

  finland: {
    name: "finland",
    code: "FI",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.FINLAND
  },

  france: {
    name: "france",
    code: "FR",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.FRANCE
  },

  georgia: {
    name: "georgia",
    code: "GE",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.GEORGIA
  },

  germany: {
    name: "germany",
    code: "DE",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.GERMANY
  },

  greece: {
    name: "greece",
    code: "GR",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.GREECE
  },

  hongKong: {
    name: "hongKong",
    code: "HK",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.HONG_KONG
  },

  hungary: {
    name: "hungary",
    code: "HU",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.HUNGARY
  },

  iceland: {
    name: "iceland",
    code: "IS",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.ICELAND
  },

  india: {
    name: "india",
    code: "IN",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.INDIA
  },

  indonesia: {
    name: "indonesia",
    code: "ID",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.INDONESIA
  },

  ireland: {
    name: "ireland",
    code: "IE",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.IRELAND
  },

  israel: {
    name: "israel",
    code: "IL",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.ISRAEL
  },

  italy: {
    name: "italy",
    code: "IT",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.ITALY
  },

  japan: {
    name: "japan",
    code: "JP",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.JAPAN
  },

  latvia: {
    name: "latvia",
    code: "LV",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.LATVIA
  },

  liechtenstein: {
    name: "liechtenstein",
    code: "LI",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.LIECHTENSTEIN
  },

  lithuania: {
    name: "lithuania",
    code: "LT",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.LITHUANIA
  },

  luxembourg: {
    name: "luxembourg",
    code: "LU",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.LUXEMBOURG
  },

  malaysia: {
    name: "malaysia",
    code: "MY",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.MALAYSIA
  },

  malta: {
    name: "malta",
    code: "MT",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.MALTA
  },

  mexico: {
    name: "mexico",
    code: "MX",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.MEXICO
  },

  netherlands: {
    name: "netherlands",
    code: "NL",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.NETHERLANDS
  },

  newZealand: {
    name: "newZealand",
    code: "NZ",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.NEW_ZEALAND
  },

  norway: {
    name: "norway",
    code: "NO",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.NORWAY
  },

  poland: {
    name: "poland",
    code: "PL",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.POLAND
  },

  portugal: {
    name: "portugal",
    code: "PT",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.PORTUGAL
  },

  romania: {
    name: "romania",
    code: "RO",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.ROMANIA
  },

  russia: {
    name: "russia",
    code: "RU",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.RUSSIA
  },

  saudiArabia: {
    name: "saudiArabia",
    code: "SA",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.SAUDI_ARABIA
  },

  singapore: {
    name: "singapore",
    code: "SG",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.SINGAPORE
  },

  slovakia: {
    name: "slovakia",
    code: "SK",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.SLOVAKIA
  },

  slovenia: {
    name: "slovenia",
    code: "SI",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.SLOVENIA
  },

  southAfrica: {
    name: "southAfrica",
    code: "ZA",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.SOUTH_AFRICA
  },

  southKorea: {
    name: "southKorea",
    code: "KR",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.SOUTH_KOREA
  },

  spain: {
    name: "spain",
    code: "ES",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.SPAIN
  },

  sweden: {
    name: "sweden",
    code: "SE",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.SWEDEN
  },

  switzerland: {
    name: "switzerland",
    code: "CH",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.SWITZERLAND
  },

  taiwan: {
    name: "taiwan",
    code: "TW",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.TAIWAN
  },

  thailand: {
    name: "thailand",
    code: "TH",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.THAILAND
  },

  ukraine: {
    name: "ukraine",
    code: "UA",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.UKRAINE
  },

  unitedArabEmirates: {
    name: "unitedArabEmirates",
    code: "AE",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.UNITED_ARAB_EMIRATES
  },

  unitedKingdom: {
    name: "unitedKingdom",
    code: "UK",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.UNITED_KINGDOM
  },

  unitedStates: {
    name: "unitedStates",
    code: "US",
    stripeVatIdType: STRIPE_VAT_ID_TYPES.UNITED_KINGDOM
  }
};

