import { authHeader } from "../../utils/auth-header";
import axios from "axios";
import { handleError } from "../../utils/errorHandler";

const BACKEND_URL = process.env.BACKEND_URL;

export const subscriptionService = {
  getDefaultPlans
};

export function getDefaultPlans() {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .get(`${BACKEND_URL}/api/plans`, requestConfig)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}
