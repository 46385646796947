import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import MEMBERS_STATUS from "../constants/membersStatus";
import { withTranslation } from "react-i18next";
import { TeamsLoader } from "./contentLoaders/TeamsLoader";

class TeamsList extends Component {
  render() {
    return (
      <ListGroup flush>
        {this.props.memberships?.map((membership, index) => (
          <ListGroupItem key={index}>
            <Row className="d-flex justify-content-center align-items-center">
              <Col className="col-auto">
                <div className="avatar">
                  {membership.team.avatarUrl ? (
                    <img
                      src={membership.team.avatarUrl}
                      className="avatar-img rounded-circle"
                    />
                  ) : (
                    <span className="avatar-title rounded-circle">
                      {membership.team.name?.charAt(0).toUpperCase()}
                    </span>
                  )}
                </div>
              </Col>
              <Col className="ml-n2">
                <h4>{membership.team.name}</h4>
                <small className="text-muted">{membership.role.name}</small>
              </Col>

              <Col className="col-auto">
                {membership.status === MEMBERS_STATUS.ACTIVE && (
                  <Button
                    color="danger"
                    className={"btn-sm"}
                    onClick={() => {
                      this.props.leaveTeam(membership);
                    }}
                  >
                    {this.props.t("account:leave")}
                  </Button>
                )}

                {membership.status === MEMBERS_STATUS.PENDING && (
                  <Button
                    color="success"
                    className={"btn-sm"}
                    onClick={() => {
                      this.props.acceptInvitation(membership.id);
                    }}
                  >
                    {this.props.t("account:acceptInvitation")}
                  </Button>
                )}

                {membership.status === MEMBERS_STATUS.INACTIVE && (
                  <Button className={"btn-sm"} disabled>
                    {this.props.t("account:inActive")}
                  </Button>
                )}
              </Col>
            </Row>
          </ListGroupItem>
        ))}

        {!this.props.memberships && <TeamsLoader />}
      </ListGroup>
    );
  }
}

TeamsList.propTypes = {
  memberships: PropTypes.array.isRequired,
  leaveTeam: PropTypes.func,
  acceptInvitation: PropTypes.func
};

export default withTranslation(["account"])(TeamsList);
