import { uploadConstants } from "../constants";
import * as FileUtil from "../../components/UploadForm/fileUtil";

const defaultModelDescriptor = (id, file) => {
  const fileWrapper = FileUtil.convertFileToFileWrapper(file);

  return {
    id: id,
    name: fileWrapper.name,
    category: undefined,
    fileDescriptor: fileWrapper,
    textureDescriptors: []
  };
};

const defaultTextureDescriptor = (id, file) => {
  return {
    id: id,
    type: "Farbe",
    fileDescriptor: FileUtil.convertFileToFileWrapper(file)
  };
};

const defaultModelUploadState = () => {
  return {
    progress: 0,
    cancelFunction: null,
    error: false
  };
};

const initialSettings = {
  modelDescriptors: [],
  activeModelId: undefined,
  trademarkRightsAccept: false,
  activeStep: 0,
  modelUploadStates: {}
};

export function upload(state = initialSettings, action) {
  switch (action.type) {
    case uploadConstants.RESET:
      return {
        ...state,
        modelDescriptors: [],
        activeModelId: undefined,
        trademarkRightsAccept: false,
        activeStep: 0
      };
    case uploadConstants.General.SET_ACTIVE_STEP:
      return { ...state, activeStep: action.activeStep };
    case uploadConstants.Models.ADD_MODEL:
      return {
        ...state,
        modelDescriptors: [
          ...state.modelDescriptors,
          defaultModelDescriptor(state.modelDescriptors.length + 1, action.file)
        ]
      };
    case uploadConstants.Models.REMOVE_MODEL:
      return {
        ...state,
        modelDescriptors: state.modelDescriptors.filter(
          element => element.id !== action.id
        )
      };
    case uploadConstants.Models.SET_MODEL_NAME:
      return {
        ...state,
        modelDescriptors: state.modelDescriptors.map(modelDescriptor => {
          if (modelDescriptor.id === action.id) {
            return {
              ...modelDescriptor,
              name: action.name
            };
          } else {
            return modelDescriptor;
          }
        })
      };
    case uploadConstants.Models.SET_MODEL_CATEGORY:
      return {
        ...state,
        modelDescriptors: state.modelDescriptors.map(modelDescriptor => {
          if (modelDescriptor.id === action.id) {
            return {
              ...modelDescriptor,
              category: action.category
            };
          } else {
            return modelDescriptor;
          }
        })
      };
    case uploadConstants.Models.SET_ACTIVE_MODEL_ID:
      return {
        ...state,
        activeModelId: action.id
      };
    case uploadConstants.Textures.ADD_TEXTURE_TO_MODEL:
      return {
        ...state,
        modelDescriptors: state.modelDescriptors.map(modelDescriptor => {
          if (modelDescriptor.id === action.modelId) {
            return {
              ...modelDescriptor,
              textureDescriptors: [
                ...modelDescriptor.textureDescriptors,
                defaultTextureDescriptor(
                  modelDescriptor.textureDescriptors.length + 1,
                  action.file
                )
              ]
            };
          } else {
            return modelDescriptor;
          }
        })
      };
    case uploadConstants.Textures.REMOVE_TEXTURE_FROM_MODEL:
      return {
        ...state,
        modelDescriptors: state.modelDescriptors.map(modelDescriptor => {
          if (modelDescriptor.id === action.modelId) {
            return {
              ...modelDescriptor,
              textureDescriptors: modelDescriptor.textureDescriptors
                .filter(element => element.id !== action.textureId)
                .map((textureDescriptor, index) => {
                  return { ...textureDescriptor, id: index + 1 };
                })
            };
          } else {
            return modelDescriptor;
          }
        })
      };
    case uploadConstants.Textures.SET_TEXTURE_TYPE:
      return {
        ...state,
        modelDescriptors: state.modelDescriptors.map(modelDescriptor => {
          if (modelDescriptor.id === action.modelId) {
            return {
              ...modelDescriptor,
              textureDescriptors: modelDescriptor.textureDescriptors.map(
                textureDescriptor => {
                  if (textureDescriptor.id === action.textureId) {
                    return { ...textureDescriptor, type: action.textureType };
                  } else {
                    return textureDescriptor;
                  }
                }
              )
            };
          } else {
            return modelDescriptor;
          }
        })
      };
    case uploadConstants.TrademarkRights.TOGGLE_TRADEMARK_RIGHTS_ACCEPT:
      return {
        ...state,
        trademarkRightsAccept: !state.trademarkRightsAccept
      };

    case uploadConstants.Upload.ADD_MODEL_TO_UPLOADING_STATE:
      return {
        ...state,
        modelUploadStates: {
          ...state.modelUploadStates,
          [action.id]: defaultModelUploadState()
        }
      };
    case uploadConstants.Upload.REMOVE_MODEL_FROM_UPLOADING_STATE:
      return {
        ...state,
        modelUploadStates: {
          ...state.modelUploadStates,
          [action.id]: undefined
        }
      };
    case uploadConstants.Upload.SET_MODEL_UPLOAD_PROGRESS:
      return {
        ...state,
        modelUploadStates: {
          ...state.modelUploadStates,
          [action.id]: {
            ...state.modelUploadStates[action.id],
            progress: action.progress
          }
        }
      };
    case uploadConstants.Upload.SET_MODEL_UPLOAD_CANCEL_FUNCTION:
      return {
        ...state,
        modelUploadStates: {
          ...state.modelUploadStates,
          [action.id]: {
            ...state.modelUploadStates[action.id],
            cancelFunction: action.cancelFunction
          }
        }
      };
    case uploadConstants.Upload.SET_MODEL_UPLOAD_ERROR:
      return {
        ...state,
        modelUploadStates: {
          ...state.modelUploadStates,
          [action.id]: { ...state.modelUploadStates[action.id], error: true }
        }
      };
    default:
      return state;
  }

}
