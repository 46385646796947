const CREDIT_CARD_TYPES = {
  AMERICAN_EXPRESS: "amex",
  DINERS: "diners",
  DISCOVER: "discover",
  JCB: "jcb",
  MASTERCARD: "mastercard",
  UNIONPAY: "unionpay",
  VISA: "visa",
  UNKNOWN: "unknown"
};

export default CREDIT_CARD_TYPES;
