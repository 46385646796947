import React, { Component } from "react";
import { billingService } from "../../services/billing.service";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown
} from "reactstrap";
import { OtherVerticalIcon } from "../customIcons/OtherVerticalIcon";
import { withTranslation } from "react-i18next";
import AddPaymentMethodForm from "./AddPaymentMethodForm";
import { openNotification } from "../../utils/notification";
import NOTIFICATION_TYPES from "../../constants/notificationTypes";
import CREDIT_CARD_TYPES from "../../constants/creditCardTypes";
import { AmericanExpressIcon } from "../customIcons/AmericanExpressIcon";
import { DinersIcon } from "../customIcons/DinersIcon";
import { DiscoverIcon } from "../customIcons/DiscoverIcon";
import { JCBIcon } from "../customIcons/JCBIcon";
import { MastercardIcon } from "../customIcons/MastercardIcon";
import { UnionPayIcon } from "../customIcons/UnionPayIcon";
import { VisaIcon } from "../customIcons/VisaIcon";
import { GenericCreditCardIcon } from "../customIcons/GenericCreditCardIcon";
import * as PropTypes from "prop-types";
import { includesPermissions } from "../../utils/includesPermissions";
import PERMISSIONS from "../../constants/permissions";
import { connect } from "react-redux";
import ERROR_CODES from "../../constants/errorCodes";

class PaymentMethodsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethods: [],
      isAddPaymentMethodModalOpen: false
    };
  }

  componentDidMount() {
    this.fetchPaymentMethods();
  }

  handleError(errorCode) {
    let messageTranslationProperty;
    switch (errorCode) {
      case ERROR_CODES.paymentMethod.PAYMENT_METHOD_NOT_EXISTS:
        messageTranslationProperty =
          "notifications:errors.paymentMethodNotExists";
        break;

      default:
        messageTranslationProperty = "notifications:errors.internalServerError";
    }

    openNotification(
      this.props.t(messageTranslationProperty),
      NOTIFICATION_TYPES.ERROR
    );
  }

  async fetchPaymentMethods() {
    const paymentMethods = await billingService.getMyPaymentMethods();

    await this.setState({
      paymentMethods: paymentMethods.data
    });
  }

  openAddPaymentMethodModal = () => {
    this.setState({
      isAddPaymentMethodModalOpen: true
    });
  };

  closeAddPaymentMethodModal = () => {
    this.setState(
      {
        isAddPaymentMethodModalOpen: false
      },
      () => {}
    );
  };

  getCreditCardIcon = brand => {
    switch (brand) {
      case CREDIT_CARD_TYPES.AMERICAN_EXPRESS:
        return <AmericanExpressIcon width="38px" />;
      case CREDIT_CARD_TYPES.DINERS:
        return <DinersIcon width="38px" />;
      case CREDIT_CARD_TYPES.DISCOVER:
        return <DiscoverIcon width="38px" />;
      case CREDIT_CARD_TYPES.JCB:
        return <JCBIcon width="38px" />;
      case CREDIT_CARD_TYPES.MASTERCARD:
        return <MastercardIcon />;
      case CREDIT_CARD_TYPES.UNIONPAY:
        return <UnionPayIcon width="38px" />;
      case CREDIT_CARD_TYPES.VISA:
        return <VisaIcon width="38px" />;
      default:
        return <GenericCreditCardIcon width="38px" />;
    }
  };

  deletePaymentMethod = async id => {
    try {
      await billingService.deletePaymentMethod(id);

      await this.fetchPaymentMethods();
    } catch (error) {
      this.handleError(error.code);
    }

    this.props.onPaymentMethodUpdated();
  };

  changeDefaultPaymentMethod = async id => {
    try {
      await billingService.changeDefaultPaymentMethod(id);

      await this.fetchPaymentMethods();
    } catch (error) {
      this.handleError(error.code);
    }
  };

  handlePaymentMethodSuccess = async stripeId => {
    await billingService.savePaymentMethod(stripeId);

    openNotification(
      this.props.t("notifications:successTitle"),
      NOTIFICATION_TYPES.SUCCESS
    );

    await this.fetchPaymentMethods();

    this.closeAddPaymentMethodModal();

    this.props.onPaymentMethodUpdated();
  };

  render() {
    return (
      <>
        <Card>
          <CardHeader>
            <h4 className="card-header-title">
              {this.props.t("settings:paymentMethodsHeader")}
            </h4>
            {includesPermissions(
              [PERMISSIONS.subscriptions.SUBSCRIPTIONS_UPDATE],
              this.props.teamMembership.permissions
            ) && (
              <Button
                className="btn-sm"
                color="primary"
                onClick={this.openAddPaymentMethodModal}
              >
                {this.props.t("settings:addPaymentMethodButton")}
              </Button>
            )}
          </CardHeader>
          <CardBody>
            <ListGroup flush>
              {this.state.paymentMethods?.map(paymentMethod => (
                <ListGroupItem key={paymentMethod.id}>
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      {this.getCreditCardIcon(paymentMethod.brand)}
                    </Col>

                    <Col className="ml-n2">
                      <h4 className="mb-1">
                        {this.props.t("settings:paymentMethodItemTitle", {
                          card: paymentMethod.brand,
                          lastFourDigits: paymentMethod.last4
                        })}
                      </h4>
                      <small className="text-muted">
                        {this.props.t("settings:paymentMethodItemSubTitle", {
                          month: paymentMethod.expiryMonth,
                          year: paymentMethod.expiryYear
                        })}
                      </small>
                    </Col>

                    {paymentMethod.isDefault && (
                      <Col className="col-auto">
                        <Badge color="light">
                          {this.props.t("settings:defaultBadge")}
                        </Badge>
                      </Col>
                    )}

                    {includesPermissions(
                      [PERMISSIONS.subscriptions.SUBSCRIPTIONS_UPDATE],
                      this.props.teamMembership.permissions
                    ) && (
                      <Col className="col-auto">
                        <UncontrolledDropdown direction="right">
                          <DropdownToggle
                            color="link"
                            className="dropdown-ellipses p-0"
                            style={{ fontSize: "20px" }}
                          >
                            <OtherVerticalIcon />
                          </DropdownToggle>

                          <DropdownMenu>
                            <DropdownItem
                              onClick={() => {
                                this.deletePaymentMethod(paymentMethod.id);
                              }}
                            >
                              {this.props.t("settings:delete")}
                            </DropdownItem>

                            {!paymentMethod.isDefault && (
                              <DropdownItem
                                onClick={() => {
                                  this.changeDefaultPaymentMethod(
                                    paymentMethod.id
                                  );
                                }}
                              >
                                {this.props.t("settings:default")}
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Col>
                    )}
                  </Row>
                </ListGroupItem>
              ))}
            </ListGroup>
          </CardBody>
        </Card>

        <Modal
          centered
          isOpen={this.state.isAddPaymentMethodModalOpen}
          toggle={this.closeAddPaymentMethodModal}
        >
          <ModalHeader
            className="card-header"
            toggle={this.closeAddPaymentMethodModal}
          >
            {this.props.t("settings:addPaymentMethodModalHeader")}
          </ModalHeader>
          <ModalBody>
            <AddPaymentMethodForm
              onSuccess={this.handlePaymentMethodSuccess}
              onError={error => {
                openNotification(error.message, NOTIFICATION_TYPES.ERROR);
              }}
            />
          </ModalBody>
        </Modal>
      </>
    );
  }
}

PaymentMethodsCard.propTypes = {
  onPaymentMethodUpdated: PropTypes.func
};

function mapStateToProps(state) {
  return {
    teamMembership: state.teamMembership
  };
}

export default connect(mapStateToProps)(
  withTranslation(["settings", "notifications"])(PaymentMethodsCard)
);
