import React, { Component } from "react";
import { CircleLoader } from "./CircleLoader";
import { RectangleLoader } from "./RectangleLoader";
import { Col, ListGroupItem, Row } from "reactstrap";

export class TeamsLoader extends Component {
  render() {
    return (
      <ListGroupItem key={"loader"}>
        <Row className="align-items-center">
          <Col className="col-auto">
            <CircleLoader r="5" height="48" width="48" />
          </Col>
          <Col className="ml-n2">
            <RectangleLoader rx="3" ry="3" width="400" height="5" />
            <RectangleLoader rx="3" ry="3" width="400" height="5" />
          </Col>
          <Col
            className={
              "col-auto d-flex align-items-center flex-column flex-sm-row"
            }
          >
            <RectangleLoader rx="3" ry="3" width="100" height="15" />
          </Col>
        </Row>
      </ListGroupItem>
    );
  }
}
