import React, { Component } from "react";
import * as PropTypes from "prop-types";

export class InfiniteLoader extends Component {
  render() {
    return (
      <div className="infinite-loader__container center">
        {this.props.text && (
          <span className="infinite-loader__text center">
            {this.props.text}
          </span>
        )}
        <div className="infinite-loader__dots center">
          <label> ●</label>
          <label> ●</label>
          <label> ●</label>
          <label> ●</label>
          <label> ●</label>
          <label> ●</label>
        </div>
      </div>
    );
  }
}

InfiniteLoader.propTypes = {
  text: PropTypes.string
};
