import { authHeader } from "../utils/auth-header";
import axios from "axios";
import { handleError } from "../utils/errorHandler";

const BACKEND_URL = process.env.BACKEND_URL;

export const billingAddressService = {
  getBillingAddress,
  deleteBillingAddress,
  saveBillingAddress
};

async function saveBillingAddress(data) {
  const requestConfig = {
    headers: authHeader()
  };

  const requestBody = data;

  return axios
    .put(
      `${BACKEND_URL}/api/billing-addresses/current`,
      requestBody,
      requestConfig
    )
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}

async function getBillingAddress() {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .get(`${BACKEND_URL}/api/billing-addresses/current`, requestConfig)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}

async function deleteBillingAddress() {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .delete(`${BACKEND_URL}/api/billing-addresses/current`, requestConfig)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}
