import { uploadConstants } from "../constants";

export const uploadActions = {
  resetUploadState,
  setActiveStep,
  addModel,
  removeModel,
  setModelName,
  setModelCategory,
  setActiveModelId,
  addTextureToModel,
  removeTextureFromModel,
  setTextureType,
  toggleTrademarkRightsAccept,
  addModelToUploadingState,
  removeModelFromUploadingState,
  setModelUploadProgress,
  setModelUploadCancelFunction,
  setModelUploadError,
};
function resetUploadState() {
  return { type: uploadConstants.RESET };
}
function setActiveStep(activeStep) {
  return { type: uploadConstants.General.SET_ACTIVE_STEP, activeStep };
}
function addModel(file) {
  return { type: uploadConstants.Models.ADD_MODEL, file };
}
function removeModel(id) {
  return { type: uploadConstants.Models.REMOVE_MODEL, id };
}
function setModelName(id, name) {
  return { type: uploadConstants.Models.SET_MODEL_NAME, id, name };
}
function setModelCategory(id, category) {
  return { type: uploadConstants.Models.SET_MODEL_CATEGORY, id, category };
}
function setActiveModelId(id) {
  return { type: uploadConstants.Models.SET_ACTIVE_MODEL_ID, id };
}
function addTextureToModel(modelId, file) {
  return { type: uploadConstants.Textures.ADD_TEXTURE_TO_MODEL, modelId, file };
}
function removeTextureFromModel(modelId, textureId) {
  return {
    type: uploadConstants.Textures.REMOVE_TEXTURE_FROM_MODEL,
    modelId,
    textureId
  };
}
function setTextureType(modelId, textureId, textureType) {
  return {
    type: uploadConstants.Textures.SET_TEXTURE_TYPE,
    modelId,
    textureId,
    textureType
  };
}
function toggleTrademarkRightsAccept() {
  return {
    type: uploadConstants.TrademarkRights.TOGGLE_TRADEMARK_RIGHTS_ACCEPT
  };
}

function addModelToUploadingState(id) {
  return { type: uploadConstants.Upload.ADD_MODEL_TO_UPLOADING_STATE, id };
}
function removeModelFromUploadingState(id) {
  return { type: uploadConstants.Upload.REMOVE_MODEL_FROM_UPLOADING_STATE, id };
}
function setModelUploadProgress(id, progress) {
  return {
    type: uploadConstants.Upload.SET_MODEL_UPLOAD_PROGRESS,
    id,
    progress
  };
}
function setModelUploadCancelFunction(id, cancelFunction) {
  return {
    type: uploadConstants.Upload.SET_MODEL_UPLOAD_CANCEL_FUNCTION,
    id,
    cancelFunction
  };
}
function setModelUploadError(id) {
  return { type: uploadConstants.Upload.SET_MODEL_UPLOAD_ERROR, id };
}

