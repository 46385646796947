import React, { Component } from "react";
import * as PropTypes from "prop-types";

export class CustomIcon extends Component {
  render() {
    return (
      <svg
        className={this.props.className}
        style={this.props.style}
        width={this.props.width || "24px"}
        height={this.props.height || "24px"}
        viewBox={
          this.props.viewBox ||
          `0 0 ${this.props.width || 24} ${this.props.height || 24}`
        }
      >
        {this.props.children}
      </svg>
    );
  }
}

CustomIcon.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string
};
