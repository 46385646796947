import { authHeader } from "../../utils/auth-header";
import axios from "axios";
import { handleError } from "../../utils/errorHandler";

const BACKEND_URL = process.env.BACKEND_URL;

export const membersService = {
  getAllMembers,
  inviteMember,
  changeMemberRole,
  deleteMember,
  getCurrentUserTeamMemberships
};

async function getCurrentUserTeamMemberships(filter) {
  const requestConfig = {
    headers: authHeader(),
    params: { ...filter }
  };

  return axios
    .get(
      `${BACKEND_URL}/api/team-memberships/current-user-memberships`,
      requestConfig
    )
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}

async function deleteMember(id) {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .delete(`${BACKEND_URL}/api/team-memberships/${id}`, requestConfig)
    .then(res => {
      return res;
    })
    .catch(error => {
      return handleError(error);
    });
}

async function changeMemberRole(memberId, data) {
  const requestConfig = {
    headers: authHeader()
  };

  const requestBody = data;

  return axios
    .put(
      `${BACKEND_URL}/api/team-memberships/${memberId}`,
      requestBody,
      requestConfig
    )
    .then(res => {
      return res;
    })
    .catch(error => {
      return handleError(error);
    });
}

async function inviteMember(name, email, roleId) {
  const requestConfig = {
    headers: authHeader()
  };

  const requestBody = {
    userEmail: email,
    name: name,
    roleId: roleId
  };

  return axios
    .post(`${BACKEND_URL}/api/team-memberships`, requestBody, requestConfig)
    .then(res => {
      return res;
    })
    .catch(error => {
      return handleError(error);
    });
}

export function getAllMembers() {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .get(`${BACKEND_URL}/api/team-memberships`, requestConfig)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}
