export function convertFileToFileWrapper(file) {
  return {
    name: file.name.split(".")[0],
    size: (file.size / 1024 / 1024).toFixed(3),
    type: file.name
      .split(".")
      .pop()
      .toUpperCase(),
    file: file
  };
}
