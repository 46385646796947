import React, { Component } from "react";
import { connect } from "react-redux";
import ROUTES from "../../constants/routes";
import { Container } from "reactstrap";
import Navigation from "./navigation/Navigation";
import Content from "./Content/Content";
import FirstLogin from "../FirstLogin";

class App extends Component {
  render() {
    return (
      <>
        {!this.props.misc.lastLogin && (
          <Container>
            <FirstLogin />
          </Container>
        )}

        {this.props.misc.lastLogin && (
          <Container fluid className="p-0 d-flex flex-column flex-lg-row">
            {!this.props.location.pathname.includes(ROUTES.editor) && (
              <Navigation />
            )}

            <Content />
          </Container>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    misc: state.misc
  };
}

export default connect(mapStateToProps)(App);
