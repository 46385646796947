import React, { Component, Fragment } from "react";
import { Button, Col, Row } from "reactstrap";
import ROUTES from "../../constants/routes";
import { Trans, withTranslation } from "react-i18next";
import { openNotification } from "../../utils/notification";
import ERROR_CODES from "../../constants/errorCodes";
import NOTIFICATION_TYPES from "../../constants/notificationTypes";
import { userService } from "../../services/user.service";

class EmailVerify extends Component {
  constructor() {
    super();

    this.state = {
      username: "",
      password: "",
      isVerified: false,
      isLoading: true
    };
  }

  handleError(errorCode) {
    let messageTranslationProperty;
    switch (errorCode) {
      case ERROR_CODES.user.EMAIL_NOT_VERIFIED:
        messageTranslationProperty = "notifications:errors.emailNotVerified";
        break;
      case ERROR_CODES.user.EMAIL_ALREADY_VERIFIED:
        messageTranslationProperty =
          "notifications:errors.emailAlreadyVerified";
        break;
      case ERROR_CODES.authentication.TOKEN_INVALID:
        messageTranslationProperty =
          "notifications:errors.tokenInvalidEmailVerification";
        break;
      default:
        messageTranslationProperty = "notifications:errors.internalServerError";
    }

    openNotification(
      this.props.t(messageTranslationProperty),
      NOTIFICATION_TYPES.ERROR
    );
  }

  async componentDidMount() {
    try {
      await userService.verifyEmail(this.props.match.params.token);

      openNotification(
        this.props.t("notifications:success.emailVerify"),
        NOTIFICATION_TYPES.SUCCESS
      );

      this.setState({ isVerified: true });
    } catch (error) {
      this.handleError(error.code);

      this.setState({ isVerified: false });
    }

    this.setState({ isLoading: false });
  }

  render() {
    return (
      <Row noGutters className="vh-100 bg-white">
        <Col
          className="col-12 col-md-8 order-md-1"
          style={{
            backgroundImage: "url('/assets/Login_Fingerprint.png')",
            backgroundSize: "cover",
            backgroundPosition: "50% 50%",
            width: "100%",
            minHeight: "300px"
          }}
        />

        <Col className="col-12 col-md-4 order-md-0 d-flex flex-column align-items-center justify-content-center p-5">
          <img
            src="/assets/Logo.svg"
            title="Augmenteers Logo"
            alt={this.props.t("authentication:authenticationImageAltText")}
            width="220px"
            height="46px"
            className="mb-3"
          />

          <h1 className="display-4 mb-3">
            {this.props.t("authentication:emailVerificationHeading")}
          </h1>

          <p className="mb-5">
            {this.state.isLoading && (
              <Fragment>
                {this.props.t("authentication:emailVerificationLoadingText")}
              </Fragment>
            )}

            {!this.state.isLoading && (
              <Fragment>
                {this.state.isVerified === true ? (
                  <Fragment>
                    {this.props.t("authentication:emailVerifiedMessage")}
                  </Fragment>
                ) : (
                  <Trans
                    i18nKey="authentication:accountNotVerifiedMessage"
                    components={[
                      <a
                        href={`mailto:${process.env.SUPPORT_EMAIL}?subject=Mein Konto konnte nicht verifiziert werden&body=Liebes Augmenteers-Team, leider konnte mein Konto nicht verifiziert werden. Meine Verifizierungs-ID lautet: ${this.props.match.params.token}`}
                      />
                    ]}
                  />
                )}
              </Fragment>
            )}
          </p>

          <Button
            color="primary"
            type="submit"
            block
            onClick={() => this.props.history.push(ROUTES.login)}
          >
            {this.props.t("authentication:toLoginButton")}
          </Button>
        </Col>
      </Row>
    );
  }
}

export default withTranslation(["notifications", "authentication"])(
  EmailVerify
);
