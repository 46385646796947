import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip
} from "reactstrap";
import ROUTES from "../../constants/routes";
import { withTranslation } from "react-i18next";
import { openNotification } from "../../utils/notification";
import ERROR_CODES from "../../constants/errorCodes";
import NOTIFICATION_TYPES from "../../constants/notificationTypes";
import { userService } from "../../services/user.service";
import PasswordInput from "../../components/PasswordInput";
import PasswordRequirementsCard from "../../components/PasswordRequirementsCard";
import ButtonWithLoadingIndicator from "../../components/ButtonWithLoadingIndicator";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      passwordConfirmation: "",
      token: props.match.params.token,
      isLoading: false
    };
  }

  handleError(errorCode) {
    let messageTranslationProperty;
    switch (errorCode) {
      case ERROR_CODES.user.USER_NOT_AUTHORIZED:
        messageTranslationProperty = "notifications:errors.userNotAuthorized";
        break;
      case ERROR_CODES.user.USER_NOT_EXISTS:
        messageTranslationProperty = "notifications:errors.userNotExists";
        break;
      case ERROR_CODES.authentication.TOKEN_INVALID:
        messageTranslationProperty =
          "notifications:errors.tokenInvalidPasswordReset";
        break;
      case ERROR_CODES.user.PASSWORD_REQUIREMENTS_NOT_MET:
        messageTranslationProperty =
          "notifications:errors.passwordRequirementsNotMet";
        break;
      default:
        return "internalServerError";
    }

    openNotification(
      this.props.t(messageTranslationProperty),
      NOTIFICATION_TYPES.ERROR
    );
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ isLoading: true });

    const { username, token, password, passwordConfirmation } = this.state;

    if (password !== passwordConfirmation) {
      openNotification(
        this.props.t("notifications:errors.passwordsNotMatch"),
        NOTIFICATION_TYPES.ERROR
      );
    } else {
      try {
        await userService.resetPassword(username, password, token);

        openNotification(
          this.props.t("notifications:success.passwordReset"),
          NOTIFICATION_TYPES.SUCCESS
        );

        this.props.history.push(ROUTES.login);
      } catch (error) {
        this.handleError(error.code);
      }
    }

    this.setState({ isLoading: false });
  };

  render() {
    return (
      <Row noGutters className="vh-100 bg-white">
        <Col
          className="col-12 col-md-8 order-md-1"
          style={{
            backgroundImage: "url('/assets/Sign_Up.png')",
            backgroundSize: "cover",
            backgroundPosition: "50% 50%",
            width: "100%",
            minHeight: "300px"
          }}
        />

        <Col className="col-12 col-md-4 order-md-0 d-flex flex-column align-items-center justify-content-center p-5">
          <img
            src="/assets/Logo.svg"
            title="Augmenteers Logo"
            alt={this.props.t("authentication:authenticationImageAltText")}
            width="220px"
            height="46px"
            className="mb-3"
          />

          <h1 className="display-4 mb-3">
            {this.props.t("authentication:resetPasswordHeading")}
          </h1>

          <p className="text-muted mb-5">
            {this.props.t("authentication:resetPasswordText")}
          </p>

          <Form onSubmit={this.handleSubmit} className="w-100">
            <FormGroup>
              <Label>
                {this.props.t("authentication:emailAddressInputLabel")}
              </Label>

              <Input
                name="username"
                type="email"
                value={this.state.username}
                required
                onChange={this.handleChange}
              />
            </FormGroup>

            <FormGroup>
              <Label>
                {this.props.t("authentication:passwordInputLabel")}{" "}
                <i id="autoHideLabel" className="fe fe-info" />
                <UncontrolledTooltip
                  target="autoHideLabel"
                  placement="right"
                  innerClassName="password-requirements-tooltip-inner"
                >
                  <PasswordRequirementsCard />
                </UncontrolledTooltip>
              </Label>

              <PasswordInput
                value={this.state.password}
                name="password"
                required
                onChange={this.handleChange}
                showPasswordStrength
              />
            </FormGroup>

            <FormGroup>
              <Label>
                {this.props.t("authentication:confirmPasswordInputLabel")}
              </Label>

              <PasswordInput
                value={this.state.passwordConfirmation}
                name="passwordConfirmation"
                required
                onChange={this.handleChange}
              />
            </FormGroup>

            <FormGroup>
              <ButtonWithLoadingIndicator
                color="primary"
                type="submit"
                block
                disabled={
                  !(
                    !!this.state.username &&
                    !!this.state.password &&
                    !!this.state.passwordConfirmation
                  )
                }
                loading={this.state.isLoading}
              >
                {this.props.t("authentication:restPasswordButton")}
              </ButtonWithLoadingIndicator>
            </FormGroup>
          </Form>

          <small className="text-muted mb-2">
            {this.props.t("authentication:noAccountText")}{" "}
            <Link to={ROUTES.signUp}>
              {this.props.t("authentication:registrationButton")}
            </Link>
          </small>

          <small className="text-muted">
            {this.props.t("authentication:rememberPasswordText")}{" "}
            <Link to={ROUTES.login}>
              {this.props.t("authentication:loginButton")}
            </Link>
          </small>
        </Col>
      </Row>
    );
  }
}

export default withTranslation(["notifications", "authentication"])(
  ResetPassword
);
