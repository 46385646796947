import React, { Component } from "react";
import { CustomIcon } from "./CustomIcon";

export class BurgerMenuIcon extends Component {
  render() {
    return (
      <CustomIcon {...this.props}>
        <path
          stroke={this.props.color || "currentColor"}
          strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M4 7h22M4 15h22M4 23h22"
        />
      </CustomIcon>
    );
  }
}

BurgerMenuIcon.propTypes = {
  ...CustomIcon.propTypes
};
