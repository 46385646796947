import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import ROUTES from "../../constants/routes";
import {
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip
} from "reactstrap";
import { Trans, withTranslation } from "react-i18next";
import { openNotification } from "../../utils/notification";
import ERROR_CODES from "../../constants/errorCodes";
import NOTIFICATION_TYPES from "../../constants/notificationTypes";
import { userService } from "../../services/user.service";
import PasswordInput from "../../components/PasswordInput";
import PasswordRequirementsCard from "../../components/PasswordRequirementsCard";
import ButtonWithLoadingIndicator from "../../components/ButtonWithLoadingIndicator";

class SignUp extends Component {
  constructor() {
    super();

    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      readTerms: false,
      isLoading: false
    };
  }

  handleError(errorCode) {
    let messageTranslationProperty;
    switch (errorCode) {
      case ERROR_CODES.user.EMAIL_EXISTS:
        messageTranslationProperty = "notifications:errors.emailExists";
        break;
      case ERROR_CODES.user.PASSWORD_REQUIREMENTS_NOT_MET:
        messageTranslationProperty =
          "notifications:errors.passwordRequirementsNotMet";
        break;
      default:
        messageTranslationProperty = "notifications:errors.internalServerError";
    }

    openNotification(
      this.props.t(messageTranslationProperty),
      NOTIFICATION_TYPES.ERROR
    );
  }

  handleSubmit = async e => {
    e.preventDefault();

    this.setState({ isLoading: true });

    const {
      firstname,
      lastname,
      email,
      password,
      passwordConfirmation
    } = this.state;

    if (password !== passwordConfirmation) {
      openNotification(
        this.props.t("notifications:errors.passwordsNotMatch"),
        NOTIFICATION_TYPES.ERROR
      );
    } else {
      try {
        await userService.register(
          firstname,
          lastname,
          email,
          password,
          passwordConfirmation
        );

        openNotification(
          this.props.t("notifications:success.register"),
          NOTIFICATION_TYPES.SUCCESS
        );

        this.props.history.push(ROUTES.login);
      } catch (error) {
        this.handleError(error.code);
      }
    }

    this.setState({ isLoading: false });
  };

  handleCheck = () => {
    this.setState({ readTerms: !this.state.readTerms });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <Row noGutters className="vh-100 bg-white">
        <Col
          className="col-12 col-md-8 order-md-1"
          style={{
            backgroundImage: "url('/assets/Sign_Up.png')",
            backgroundSize: "cover",
            backgroundPosition: "50% 50%",
            width: "100%",
            minHeight: "300px"
          }}
        />

        <Col className="col-12 col-md-4 order-md-0 d-flex flex-column align-items-center justify-content-center p-5">
          <img
            src="/assets/Logo.svg"
            title="Augmenteers Logo"
            alt={this.props.t("authentication:authenticationImageAltText")}
            width="220px"
            height="46px"
            className="mb-3"
          />

          <h1 className="display-4 mb-3">
            {this.props.t("authentication:registrationPageHeading")}
          </h1>

          <p className="text-muted mb-5">
            {this.props.t("authentication:registrationPageText")}
          </p>

          <Form onSubmit={this.handleSubmit} className="w-100">
            <Row>
              <Col className="col-12 col-md-6">
                <FormGroup>
                  <Label>
                    {this.props.t("authentication:firstNameInputLabel")}
                  </Label>

                  <Input
                    name="firstname"
                    type="text"
                    value={this.state.firstname}
                    required
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>

              <Col className="col-12 col-md-6">
                <FormGroup>
                  <Label>
                    {this.props.t("authentication:lastNameInputLabel")}
                  </Label>

                  <Input
                    name="lastname"
                    type="text"
                    value={this.state.lastname}
                    required
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label>
                {this.props.t("authentication:emailAddressInputLabel")}
              </Label>

              <Input
                name="email"
                type="email"
                value={this.state.email}
                required
                onChange={this.handleChange}
              />
            </FormGroup>

            <FormGroup>
              <Label>
                {this.props.t("authentication:passwordInputLabel")}{" "}
                <i id="autoHideLabel" className="fe fe-info" />
                <UncontrolledTooltip
                  target="autoHideLabel"
                  placement="right"
                  innerClassName="password-requirements-tooltip-inner"
                >
                  <PasswordRequirementsCard />
                </UncontrolledTooltip>
              </Label>

              <PasswordInput
                value={this.state.password}
                name="password"
                onChange={this.handleChange}
                required
                showPasswordStrength
              />
            </FormGroup>

            <FormGroup>
              <Label>
                {this.props.t("authentication:confirmPasswordInputLabel")}
              </Label>

              <PasswordInput
                value={this.state.passwordConfirmation}
                name="passwordConfirmation"
                onChange={this.handleChange}
                required
              />
            </FormGroup>

            <FormGroup>
              <CustomInput
                type="checkbox"
                id="termsCheckbox"
                onChange={this.handleCheck}
                checked={this.state.readTerms}
                label={
                  <Trans
                    i18nKey="authentication:acceptLegalTermsCheckbox"
                    components={[
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://augmenteers.com/de/agbs/"
                      />,
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://augmenteers.com/de/datenschutz/"
                      />
                    ]}
                  />
                }
              />
            </FormGroup>

            <FormGroup>
              <ButtonWithLoadingIndicator
                color="primary"
                type="submit"
                block
                disabled={
                  !(
                    !!this.state.firstname &&
                    !!this.state.lastname &&
                    !!this.state.email &&
                    !!this.state.password &&
                    !!this.state.passwordConfirmation &&
                    this.state.readTerms
                  )
                }
                loading={this.state.isLoading}
              >
                {this.props.t("authentication:registrationButtonText")}
              </ButtonWithLoadingIndicator>
            </FormGroup>
          </Form>

          <small className="text-muted">
            {this.props.t("authentication:alreadyHaveAccountText")}{" "}
            <Link to={ROUTES.login}>
              {this.props.t("authentication:loginButton")}
            </Link>
          </small>
        </Col>
      </Row>
    );
  }
}

export default withTranslation(["notifications", "authentication"])(SignUp);
