import React, { Component } from "react";
import { billingService } from "../services/billing.service";
import { PlanType } from "../constants/planType";
import PlanSelection from "./PlanSelection";
import PaymentMethods from "./team/PaymentMethodsCard";
import BillingAddress from "./team/BillingAddress";
import { Button, Col } from "reactstrap";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { billingAddressService } from "../services/billingAddress.service";
import PropTypes from "prop-types";
import ERROR_CODES from "../constants/errorCodes";
import { openNotification } from "../utils/notification";
import NOTIFICATION_TYPES from "../constants/notificationTypes";

class PlanUpgrade extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUpgradeModelOpen: false,
      activeTab: 0,
      upgradeModalContinue: this.props.isFirstLogin,
      name: "",
      street: "",
      postCode: "",
      city: "",
      country: "",
      state: "",
      phone: "",
      newPlan: undefined
    };
  }

  handleError(errorCode) {
    let messageTranslationProperty;
    switch (errorCode) {
      case ERROR_CODES.team.TEAM_NOT_EXISTS:
        messageTranslationProperty = "notifications:errors.teamNotExists";
        break;

      case ERROR_CODES.team.SUBSCRIPTION_NOT_EXISTS:
        messageTranslationProperty =
          "notifications:errors.subscriptionNotExists";
        break;

      case ERROR_CODES.team.PLAN_NOT_EXISTS:
        messageTranslationProperty = "notifications:errors.planNotExists";
        break;

      default:
        messageTranslationProperty = "notifications:errors.internalServerError";
    }

    openNotification(
      this.props.t(messageTranslationProperty),
      NOTIFICATION_TYPES.ERROR
    );
  }

  handleBillingAddressSubmit = async () => {
    await billingAddressService.saveBillingAddress({
      name: this.state.name,
      street: this.state.street,
      postCode: this.state.postCode,
      city: this.state.city,
      country: this.state.country,
      state: this.state.state,
      phone: this.state.phone
    });
  };

  validateAddressForm = () => {
    if (
      !!this.state.name &&
      !!this.state.street &&
      !!this.state.postCode &&
      !!this.state.city &&
      !!this.state.country
    ) {
      this.setState({ upgradeModalContinue: true });
    } else {
      this.setState({ upgradeModalContinue: false });
    }
  };

  planSelection = plan => {
    this.setState(
      {
        newPlan:
          plan.id !== this.props.team.subscription.plan.id ? plan : undefined
      },
      this.isContinueDisabled
    );
  };

  onChange = (name, value) => {
    this.setState({ [name]: value }, this.validateAddressForm);
  };

  isContinueDisabled = async () => {
    switch (this.state.activeTab) {
      case 0:
        if (
          !this.state.newPlan ||
          this.state.newPlan?.id === this.props.team.subscription.plan.id
        ) {
          this.setState({
            upgradeModalContinue: this.props.isFirstLogin
          });
        } else {
          this.setState({
            upgradeModalContinue: true
          });
        }
        break;
      case 1:
        const paymentMethods = await billingService.getMyPaymentMethods();
        this.setState({
          upgradeModalContinue: paymentMethods?.data.length >= 1
        });
        break;
      case 2:
        const address = await billingAddressService.getBillingAddress();
        if (!address) {
          await this.setState({
            upgradeModalContinue: !!address
          });
        } else {
          await this.setState({
            name: address.name,
            street: address.street,
            postCode: address.postCode,
            city: address.city,
            country: address.country,
            state: address.state,
            phone: address.phone,
            upgradeModalContinue: true
          });
        }
        break;
    }
  };

  handleContinueClick = async () => {
    if (this.state.activeTab === 2) {
      await this.handleBillingAddressSubmit();

      this.setState({
        upgradeModalContinue: false
      });
      try {
        await billingService.updateSubscription({
          planId: this.state.newPlan?.id
        });
      } catch (error) {
        this.handleError(error.code);
      }

      this.props.upgradeFinished();
    }

    if (this.state.newPlan && this.state.newPlan?.type !== PlanType.LITE) {
      await this.setState(
        {
          activeTab: this.state.activeTab + 1
        },
        async () => {
          await this.isContinueDisabled();
        }
      );
    } else if (
      this.state.newPlan &&
      this.state.newPlan?.id !== this.props.team.subscription.plan.id
    ) {
      this.setState({
        upgradeModalContinue: false
      });
      try {
        await billingService.updateSubscription({
          planId: this.state.newPlan?.id
        });
      } catch (error) {
        this.handleError(error.code);
      }

      this.props.upgradeFinished();
    } else {
      this.props.upgradeFinished();
    }
  };

  getActiveTab = () => {
    switch (this.state.activeTab) {
      case 0:
        return <PlanSelection onPlanSelection={this.planSelection} />;
      case 1:
        return (
          <PaymentMethods onPaymentMethodUpdated={this.isContinueDisabled} />
        );
      case 2:
        return (
          <BillingAddress
            name={this.state.name}
            postCode={this.state.postCode}
            street={this.state.street}
            city={this.state.city}
            state={this.state.state}
            country={this.state.country}
            phone={this.state.phone}
            onChange={this.onChange}
          />
        );
    }
  };

  handleBackButtonClick = async () => {
    this.setState({
      activeTab: this.state.activeTab - 1
    });
  };
  render() {
    return (
      <>
        {this.getActiveTab()}
        {this.state.activeTab !== 0 && (
          <Button color={"primary"} onClick={this.handleBackButtonClick}>
            {this.props.t("teams:backButton")}
          </Button>
        )}

        <Button
          color="primary"
          className="float-right"
          onClick={this.handleContinueClick}
          disabled={!this.state.upgradeModalContinue}
        >
          {this.props.t("teams:continueButton")}
        </Button>
      </>
    );
  }
}

PlanUpgrade.protoType = {
  upgradeFinished: PropTypes.func,
  isFirstLogin: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    team: state.team
  };
}

export default connect(mapStateToProps)(
  withTranslation(["teams"])(PlanUpgrade)
);
