import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

class RoutedNavLink extends Component {
  render() {
    return (
      <NavLink to={this.props.to} className="nav-link">
        {this.props.children}
      </NavLink>
    );
  }
}

export default RoutedNavLink;

RoutedNavLink.propTypes = {
  to: PropTypes.string.isRequired
};
