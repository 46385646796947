import React, { Component } from "react";
import { CustomIcon } from "./CustomIcon";

export class BrushIcon extends Component {
  render() {
    return (
      <CustomIcon {...this.props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect id="bound" x="0" y="0" width="24" height="24"></rect>
          <path
            d="M16.0576695,5.11553395 L18.1789899,7.23685429 L15.7041161,11.8330484 L20.3003102,9.35817464 L22.0680772,11.1259416 C22.2633393,11.3212037 22.2633393,11.6377862 22.0680772,11.8330484 L16.7647763,17.1363492 L6.86528137,7.23685429 L12.1685822,1.93355343 C12.3638444,1.73829129 12.6804269,1.73829129 12.875689,1.93355343 L14.643456,3.70132039 L13.9363492,5.82264073 L16.0576695,5.11553395 Z"
            fill={this.props.color || "currentColor"}
            opacity="0.3"
          ></path>
          <path
            d="M10.1599151,16.1883423 L6.86835729,20.8905356 C6.23492536,21.7954322 4.98786373,22.0154978 4.08296718,21.3820658 C3.98751044,21.3152457 3.8980757,21.2402114 3.81568357,21.1578192 L2.85771147,20.1998471 C2.07666289,19.4187986 2.07666289,18.1524686 2.85771147,17.37142 C2.94393183,17.2851997 3.0378564,17.2070448 3.13831183,17.1379318 L7.86059197,13.8890191 L4.74396103,10.7723882 C4.35343673,10.3818639 4.35343673,9.74869893 4.74396103,9.35817464 L6.15817459,7.94396107 L16.0576695,17.843456 L14.643456,19.2576696 C14.2529317,19.6481939 13.6197667,19.6481939 13.2292424,19.2576696 L10.1599151,16.1883423 Z M4.74396103,19.2576696 C5.13448532,19.6481939 5.7676503,19.6481939 6.15817459,19.2576696 C6.54869888,18.8671453 6.54869888,18.2339803 6.15817459,17.843456 C5.7676503,17.4529317 5.13448532,17.4529317 4.74396103,17.843456 C4.35343673,18.2339803 4.35343673,18.8671453 4.74396103,19.2576696 Z"
            fill={this.props.color || "currentColor"}
          ></path>
        </g>
      </CustomIcon>
    );
  }
}

BrushIcon.propTypes = {
  ...CustomIcon.propTypes
};
