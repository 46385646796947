import axios from "axios";
import { authHeader } from "../utils/auth-header";
import { handleError } from "../utils/errorHandler";

export const billingService = {
  getMyPaymentMethods,
  getMyInvoices,
  getInvoicePdfUrl,
  deletePaymentMethod,
  changeDefaultPaymentMethod,
  getStripeClientSecret,
  savePaymentMethod,
  updateVatId,
  deleteVatId,
  updateSubscription
};

async function updateSubscription(data) {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .put(`${process.env.BACKEND_URL}/api/subscriptions`, data, requestConfig)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}

async function deleteVatId(teamId) {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .delete(`${process.env.BACKEND_URL}/api/teams/${teamId}/vat`, requestConfig)
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}

async function updateVatId(teamId, data) {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .put(
      `${process.env.BACKEND_URL}/api/teams/${teamId}/vat`,
      data,
      requestConfig
    )
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}

async function savePaymentMethod(stripeId) {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .post(
      `${process.env.BACKEND_URL}/api/payment-methods`,
      { stripeId: stripeId },
      requestConfig
    )
    .then(res => {
      return res;
    })
    .catch(error => {
      return handleError(error);
    });
}

async function changeDefaultPaymentMethod(id) {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .put(
      `${process.env.BACKEND_URL}/api/payment-methods/${id}/default`,
      undefined,
      requestConfig
    )
    .then(res => {
      return res;
    })
    .catch(error => {
      return handleError(error);
    });
}

async function deletePaymentMethod(id) {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .delete(
      `${process.env.BACKEND_URL}/api/payment-methods/${id}`,
      requestConfig
    )
    .then(res => {
      return res;
    })
    .catch(error => {
      return handleError(error);
    });
}

async function getInvoicePdfUrl(id) {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .get(`${process.env.BACKEND_URL}/api/invoices/${id}/pdf`, requestConfig)
    .then(res => {
      return res.data ;
    })
    .catch(error => {
      return handleError(error);
    });
}

async function getMyInvoices() {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .get(`${process.env.BACKEND_URL}/api/invoices/my-invoices`, requestConfig)
    .then(res => {
      return { data: res.data };
    })
    .catch(error => {
      return handleError(error);
    });
}

async function getMyPaymentMethods() {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .get(
      `${process.env.BACKEND_URL}/api/payment-methods/my-payment-methods`,
      requestConfig
    )
    .then(res => {
      return { data: res.data };
    })
    .catch(error => {
      return handleError(error);
    });
}

async function getStripeClientSecret() {
  const requestConfig = {
    headers: authHeader()
  };

  return axios
    .get(
      `${process.env.BACKEND_URL}/api/payment-methods/client-secret`,
      requestConfig
    )
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return handleError(error);
    });
}
