import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import loadable from "react-loadable";
import { LoadableLoader } from "../../LoadableLoader";
import { setJustLoggedIn } from "../../../store/actions/misc.actions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { membersService } from "../../../services/team/members.service";
import MEMBERS_STATUS from "../../../constants/membersStatus";
import {
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { teamsService } from "../../../services/team/teamsService";
import { openNotification } from "../../../utils/notification";
import NOTIFICATION_TYPES from "../../../constants/notificationTypes";
import ERROR_CODES from "../../../constants/errorCodes";
import ROUTES from "../../../constants/routes";
import TeamsList from "../../TeamsList";

const Dashboard = loadable({
  loader: () => import("../../../routes/dashboard/Dashboard"),
  loading: LoadableLoader
});

const MyThreeDModelsIndex = loadable({
  loader: () =>
    import("../../../routes/3d-models/my-index/MyThreeDModelsIndex"),
  loading: LoadableLoader
});

const ThreeDModelEditor = loadable({
  loader: () => import("../../../routes/3d-models/editor/ThreeDModelsEditor"),
  loading: LoadableLoader
});

const ThreeDModelsCreate = loadable({
  loader: () => import("../../../routes/3d-models/create/ThreeDModelsCreate"),
  loading: LoadableLoader
});

const ThreeDModelsAnalytics = loadable({
  loader: () =>
    import("../../../routes/3d-models/analytics/ThreeDModelAnalytics"),
  loading: LoadableLoader
});

const Account = loadable({
  loader: () => import("../../../routes/users/account/Account"),
  loading: LoadableLoader
});

const UserManagement = loadable({
  loader: () => import("../../../routes/users/UserManagement"),
  loading: LoadableLoader
});

const TeamManagement = loadable({
  loader: () => import("../../../routes/team/Teams"),
  loading: LoadableLoader
});

const Settings = loadable({
  loader: () => import("../../../routes/settings/Settings"),
  loading: LoadableLoader
});

class Content extends Component {
  constructor() {
    super();

    this.state = {
      pendingTeamMemberships: undefined
    };
  }

  async componentDidMount() {
    await this.fetchPendingMemberships();
  }

  handleError(errorCode) {
    let messageTranslationProperty;
    switch (errorCode) {
      case ERROR_CODES.team.TEAM_MEMBERSHIP_NOT_EXISTS:
        messageTranslationProperty = "notifications:errors.membershipNotExists";
        break;
      case ERROR_CODES.team.TEAM_NOT_EXISTS:
        messageTranslationProperty = "notifications:errors.teamNotExists";
        break;
      case ERROR_CODES.team.TEAM_MEMBERSHIP_NOT_ACTIVE:
        messageTranslationProperty = "notifications:errors.membershipNotActive";
        break;
      case ERROR_CODES.team.TEAM_MEMBERSHIP_NOT_PENDING:
        messageTranslationProperty =
          "notifications:errors.membershipNotPending";
        break;
      case ERROR_CODES.team.TEAM_REQUIRES_ADMIN:
        messageTranslationProperty = "notifications:errors.teamRequiresAdmin";
        break;
      default:
        messageTranslationProperty = "notifications:errors.internalServerError";
    }

    openNotification(
      this.props.t(messageTranslationProperty),
      NOTIFICATION_TYPES.ERROR
    );
  }

  async fetchPendingMemberships() {
    if (this.props.misc.justLoggedIn) {
      const pendingTeamMemberships = await membersService.getCurrentUserTeamMemberships(
        { status: MEMBERS_STATUS.PENDING }
      );

      this.setState({
        pendingTeamMemberships: pendingTeamMemberships
      });
    }
  }

  acceptInvitation = async id => {
    try {
      await teamsService.acceptTeamInvitation(id);
      openNotification(
        this.props.t("notifications:success.teamAccept"),
        NOTIFICATION_TYPES.SUCCESS
      );
      await this.fetchPendingMemberships();
    } catch (error) {
      this.handleError(error.code);
    }
  };

  render() {
    return (
      <>
        <div className="main-content w-100">
          <Switch>
            <Route path={ROUTES.home} component={Dashboard} />
            <Route path={ROUTES.account.root} component={Account} />
            <Route path={ROUTES.settings.root} component={Settings} />
            <Route
              path={`${ROUTES.editor}/:id`}
              component={ThreeDModelEditor}
            />
            <Route
              exact
              path={ROUTES.uploadProduct}
              component={ThreeDModelsCreate}
            />
            <Route
              exact
              path={ROUTES.productList}
              component={MyThreeDModelsIndex}
            />
            <Route
              exact
              path={`${ROUTES.productAnalytics}/:id`}
              component={ThreeDModelsAnalytics}
            />
            <Route exact path={ROUTES.users} component={UserManagement} />
            <Route path={ROUTES.manageTeam.root} component={TeamManagement} />
            <Redirect to={ROUTES.home} />
          </Switch>
        </div>

        <Modal
          isOpen={
            this.state.pendingTeamMemberships?.length > 0 &&
            this.props.misc.justLoggedIn
          }
          className={"modal-lg modal-dialog-centered"}
          toggle={() => {
            this.props.setJustLoggedIn(false);
          }}
        >
          <ModalHeader
            className="card-header"
            toggle={() => {
              this.props.setJustLoggedIn(false);
            }}
          >
            {this.props.t("account:pendingMemberships")}
          </ModalHeader>
          <ModalBody>
            <TeamsList
              acceptInvitation={this.acceptInvitation}
              memberships={this.state.pendingTeamMemberships}
            />
          </ModalBody>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    misc: state.misc
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setJustLoggedIn: justLoggedIn => {
      dispatch(setJustLoggedIn(justLoggedIn));
    }
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation(["notifications", "account"])(Content))
);
