import * as TeamActions from "../actions/team.actions";
import { createReducer } from "./index";

export const team = createReducer(
  {
    team: undefined
  },
  {
    [TeamActions.SET_TEAM]: (state, action) => {
      const team = action.team;
      return team;
    }
  }
);
