import React, { Component } from "react";

export class LoadableLoader extends Component {
  render() {
    if (this.props.error) {
      // When the loader has errored
      return (
        <div>
          Error! <button onClick={this.props.retry}>Retry</button>
        </div>
      );
    } else if (this.props.timedOut) {
      // When the loader has taken longer than the timeout
      return (
        <div>
          Taking a long time...{" "}
          <button onClick={this.props.retry}>Retry</button>
        </div>
      );
    } else if (this.props.pastDelay) {
      // When the loader has taken longer than the delay
      return (
        <div className="text-center">
          <div className="spinner-border text-primary">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      // When the loader has just started
      return null;
    }
  }
}
