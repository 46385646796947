import React, { Component } from "react";
import { Card, CardBody } from "reactstrap";
import { withTranslation } from "react-i18next";

class PasswordRequirementsCard extends Component {
  render() {
    return (
      <Card className="bg-light border password-requirements-card-md">
        <CardBody>
          <label>{this.props.t("account:passwordRequirementsHeader")}</label>

          <p className="small text-muted">
            {this.props.t("account:passwordRequirementsText")}
          </p>

          <ul className="small text-muted">
            <li>
              {this.props.t("account:passwordRequirementsMinimumCharacters")}
            </li>
            <li>
              {this.props.t("account:passwordRequirementsLetter") +
                " (a-z, A-Z)"}
            </li>
            <li>
              {this.props.t("account:passwordRequirementsNumber") + " (0-9)"}
            </li>
            <li>
              {this.props.t("account:passwordRequirementsSpecialCharacter") +
                " (`!@#$%^&*()_+-=[]{};':\"|,.<>\\/?~)"}
            </li>
            <li>{this.props.t("account:passwordRequirementsNoDiacritics")}</li>
          </ul>
        </CardBody>
      </Card>
    );
  }
}

export default withTranslation(["account"])(PasswordRequirementsCard);
