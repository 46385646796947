import React, { Component } from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import ROUTES from "../../constants/routes";

class PrivateRoute extends Component {
  render() {
    const Component = this.props.component;

    return (
      <>
        {localStorage.getItem("Vrid") ? (
          <>
            {this.props.location.state?.redirect ? (
              <Redirect
                to={{
                  pathname: this.props.location.state?.redirect,
                  state: { redirect: undefined }
                }}
              />
            ) : (
              <Route component={Component} />
            )}
          </>
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.login,
              state: { redirect: this.props.location.pathname }
            }}
          />
        )}
      </>
    );
  }
}

export default withRouter(PrivateRoute);
