import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const i18n = i18next.createInstance();
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(HttpApi)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    load: "languageOnly",
    fallbackLng: "en",
    supportedLngs: ["en", "de"],
    debug: false,

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },

    // react i18next special options (optional)
    // override if needed - omit if ok with defaults

    react: {
      //bindI18n: 'languageChanged',
      //bindI18nStore: '',
      //transEmptyNodeValue: '',
      //transSupportBasicHtmlNodes: true,
      //transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true
    },

    detection: {
      // order and from where user language should be detected
      //order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      order: ["navigator"],

      caches: []
    }
  });

export default i18n;
