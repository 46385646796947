import i18n from "../i18n";
import React from "react";
import { toast } from "react-toastify";
import { Button, ToastBody } from "reactstrap";
import NOTIFICATION_TYPES from "../constants/notificationTypes";
import { DoneIcon } from "../components/customIcons/DoneIcon";
import { InfoIcon } from "../components/customIcons/InfoIcon";
import { WarningIcon } from "../components/customIcons/WarningIcon";
import { ErrorIcon } from "../components/customIcons/ErrorIcon";

export function openNotification(message, type) {
  let icon;
  switch (type) {
    case NOTIFICATION_TYPES.SUCCESS:
      icon = <DoneIcon className="text-success" />;
      break;
    case NOTIFICATION_TYPES.WARNING:
      icon = <WarningIcon className="text-warning" />;
      break;
    case NOTIFICATION_TYPES.ERROR:
      icon = <ErrorIcon className="text-danger" />;
      break;
    default:
      icon = <InfoIcon className="text-info" />;
  }

  toast(
    <>
      <div className="toast-header">
        <i className="mr-2">{icon}</i>
        <strong className="mr-auto">
          {i18n.t(`notifications:${type}Title`)}
        </strong>
        <Button close />
      </div>
      <ToastBody>{message}</ToastBody>
    </>,
    {
      position: toast.POSITION.TOP_RIGHT,
      type: type,
      closeButton: false,
      icon: false,
      className: "p-0",
      bodyClassName: "p-0"
    }
  );
}
