import * as MiscActions from "../actions/misc.actions";
import { createReducer } from "./index";

export const misc = createReducer(
  {
    justLoggedIn: false,
    lastLogin: undefined
  },
  {
    [MiscActions.SET_JUST_LOGGED_IN]: (state, action) => {
      const justLoggedIn = action.justLoggedIn;
      return { ...state, justLoggedIn: justLoggedIn };
    },
    [MiscActions.SET_LAST_LOGIN]: (state, action) => {
      const lastLogin = action.lastLogin;
      return { ...state, lastLogin: lastLogin };
    }
  }
);
