import * as TeamMembershipActions from "../actions/teamMembership.actions";
import { createReducer } from "./index";

export const teamMembership = createReducer(
  {
    permissions: undefined
  },
  {
    [TeamMembershipActions.SET_PERMISSIONS]: (state, action) => {
      const permissions = action.permissions;
      return { ...state, permissions: permissions };
    }
  }
);
