import "core-js/stable";
import React, { Suspense } from "react";
import { render } from "react-dom";
import { persistor, store, history } from "./store";

import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import { InfiniteLoader } from "./components/InfiniteLoader";

// needed for react-flatpicker to work
import "../node_modules/flatpickr/dist/flatpickr.min.css";

// needed for react-toastify to work
import "react-toastify/dist/ReactToastify.css";

import "./customDashkitChartJsStyle";
import "./styles/dashkit/css/theme.min.css";
import "./styles/dashkit/fonts/feather/feather.css";
import "./styles/custom.scss";
import { PersistGate } from "redux-persist/integration/react";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import Auth from "./routes/auth/Auth";
import PrivateRoute from "./components/Layout/PrivateRoute";
import App from "./components/Layout/App";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";

render(
  <Suspense fallback={<InfiniteLoader />}>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <>
              <Switch>
                <Route path="/auth" component={Auth} />
                <PrivateRoute component={App} />
              </Switch>

              <ToastContainer />
            </>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </I18nextProvider>
  </Suspense>,
  document.querySelector("#augmenteers-members")
);
