import React, { Component } from "react";
import { CustomIcon } from "./CustomIcon";

export class ErrorIcon extends Component {
  render() {
    return (
      <CustomIcon {...this.props}>
        <path
          opacity="0.3"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          fill={this.props.color || "currentColor"}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0355 10.6213L14.864 7.79289C15.2545 7.40237 15.8877 7.40237 16.2782 7.79289C16.6687 8.18342 16.6687 8.81658 16.2782 9.20711L13.4497 12.0355L16.2782 14.864C16.6687 15.2545 16.6687 15.8877 16.2782 16.2782C15.8877 16.6687 15.2545 16.6687 14.864 16.2782L12.0355 13.4497L9.20711 16.2782C8.81658 16.6687 8.18342 16.6687 7.79289 16.2782C7.40237 15.8877 7.40237 15.2545 7.79289 14.864L10.6213 12.0355L7.79289 9.20711C7.40237 8.81658 7.40237 8.18342 7.79289 7.79289C8.18342 7.40237 8.81658 7.40237 9.20711 7.79289L12.0355 10.6213Z"
          fill={this.props.color || "currentColor"}
        />
      </CustomIcon>
    );
  }
}

ErrorIcon.propTypes = {
  ...CustomIcon.propTypes
};
