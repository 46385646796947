import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Login from "./Login";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import EmailVerify from "./EmailVerify";
import { Container } from "reactstrap";
import ROUTES from "../../constants/routes";

class Auth extends Component {
  render() {
    return (
      <Container fluid className="p-0">
        <Switch>
          <Route path={ROUTES.login} component={Login} />
          <Route path={ROUTES.signUp} component={SignUp} />
          <Route path={ROUTES.forgotPassword} component={ForgotPassword} />
          <Route
            path={`${ROUTES.resetPassword}/:token`}
            component={ResetPassword}
          />
          <Route
            path={`${ROUTES.verifyEmail}/:token`}
            component={EmailVerify}
          />
        </Switch>
      </Container>
    );
  }
}

export default Auth;
