import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import {
  Badge,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row
} from "reactstrap";
import InputGroup from "reactstrap/es/InputGroup";
import { openNotification } from "../../utils/notification";
import ERROR_CODES from "../../constants/errorCodes";
import NOTIFICATION_TYPES from "../../constants/notificationTypes";
import { teamsService } from "../../services/team/teamsService";
import { connect } from "react-redux";
import { setTeam } from "../../store/actions/team.actions";

class Domains extends Component {
  constructor(props) {
    super(props);

    this.state = {
      domains: props.team?.domains?.split(";") || [],
      domainInput: "",
      isSubmitting: false
    };
  }

  handleError(errorCode) {
    let messageTranslationProperty;
    switch (errorCode) {
      case ERROR_CODES.user.USER_NOT_EXISTS:
        messageTranslationProperty = "notifications:errors.userNotExists";
        break;
      case ERROR_CODES.user.EMAIL_EXISTS:
        messageTranslationProperty = "notifications:errors.emailExists";
        break;
      default:
        messageTranslationProperty = "notifications:errors.internalServerError";
    }

    openNotification(
      this.props.t(messageTranslationProperty),
      NOTIFICATION_TYPES.ERROR
    );
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  removeDomain = async index => {
    let { domains } = this.state;

    if (domains.length === 1) {
      domains = [];
    } else {
      domains.splice(index, 1);
    }

    this.setState({ domains: domains });

    try {
      const currentTeam = await teamsService.updateTeamDomains(
        this.props.team.id,
        {
          domains: domains.length > 0 ? domains.join(";") : null
        }
      );

      this.props.setTeam(currentTeam);
    } catch (error) {
      this.handleError(error.code);
    }
  };

  addDomain = async () => {
    if (this.state.domainInput) {
      const { domains } = this.state;

      if (domains[0] === "*") {
        domains.splice(0, 1);
      }

      domains.push(this.state.domainInput);
      try {
        const currentTeam = await teamsService.updateTeamDomains(
          this.props.team.id,
          {
            domains: domains.join(";")
          }
        );
        this.props.setTeam(currentTeam);

        await this.setState({
          domainInput: "",
          isSubmitting: !this.state.isSubmitting
        });
      } catch (error) {
        await this.setState({
          domainInput: "",
          isSubmitting: this.state.isSubmitting
        });

        this.handleError(error.code);
      }
    }
  };

  allowAllDomains = async () => {
    await this.setState({ domains: ["*"] });

    try {
      const currentTeam = await teamsService.updateTeamDomains(
        this.props.team.id,
        {
          domains: this.state.domains.join(";")
        }
      );

      this.props.setTeam(currentTeam);

      await this.setState({
        domainInput: "",
        isSubmitting: !this.state.isSubmitting
      });
    } catch (error) {
      await this.setState({
        domainInput: "",
        isSubmitting: !this.state.isSubmitting
      });
      this.handleError(error.code);
    }
  };

  render() {
    return (
      <Fragment>
        <FormGroup row>
          <Col className="col-12">
            <h2>{this.props.t("settings:domainsHeader")}</h2>

            <p className="text-muted">{this.props.t("settings:domainsText")}</p>

            <Button color="secondary" onClick={this.allowAllDomains}>
              {this.props.t("settings:allowAllDomainsButton")}
            </Button>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col className="col-12">
            <Card>
              <CardHeader>
                <h4 className="card-header-title mr-auto">
                  {this.props.t("settings:authorizedDomainsHeader")}
                </h4>

                <Badge color="soft-secondary">
                  {this.props.t("settings:numberOfAuthorizedDomainsBadgeText", {
                    count: this.state.domains?.length
                  })}
                </Badge>
              </CardHeader>
              <CardHeader>
                <InputGroup className="input-group-flush input-group-merge">
                  <Input
                    name="domainInput"
                    type="text"
                    className="form-control-prepended"
                    placeholder={this.props.t("settings:addDomainPlaceholder")}
                    value={this.state.domainInput}
                    onKeyPress={e => {
                      if (e.key === "Enter" && !!this.state.domainInput) {
                        this.addDomain();
                      }
                    }}
                    onChange={this.handleChange}
                  />
                  <Button
                    color="primary"
                    onClick={this.addDomain}
                    disabled={!this.state.domainInput}
                  >
                    {this.props.t("settings:addDomainButton")}
                  </Button>
                </InputGroup>
              </CardHeader>
              <CardFooter>
                {this.state.domains?.map((domain, index) => (
                  <Row key={index}>
                    <Col className="col-auto d-flex align-items-center">
                      <i className="fe fe-check-circle text-success" />
                    </Col>
                    <Col>
                      <span>{domain}</span>
                    </Col>
                    <Col className="col-auto">
                      <Button
                        close
                        onClick={() => {
                          this.removeDomain(index);
                        }}
                      >
                        <i className="fe fe-x text-secondary" />
                      </Button>
                    </Col>
                  </Row>
                ))}
              </CardFooter>
            </Card>
          </Col>
        </FormGroup>
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setTeam: team => {
      dispatch(setTeam(team));
    }
  };
};

function mapStateToProps(state) {
  return {
    team: state.team
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["settings", "notifications"])(Domains));
